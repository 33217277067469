import React from "react";
import Menu from "../../project/pages/menu";
import Login from "../../public/login";
import Page404 from "../../project/pages/page404";
import UserType from "../../project/pages/user/userType";
import UserList from "../../project/pages/user/userList";
import Dashboard from "../../project/pages/dashboard";
import LocationTracking from "../../project/pages/locationTracking/locationTracking";
import PageSettings from "../../project/pages/settings/pageSettings";
import Admin from "../../project/pages/admin";
import Shop from "../../project/pages/shop";
import Product from "../../project/pages/product";
import SalesMan from "../../project/pages/salesman";
import MyVisit from "../../project/pages/myVisit";
import MyService from "../../project/pages/myService";
import TransferProduct from "../../project/pages/TransferItem";
import ProductTransfer from "../../project/pages/productTransfer";
import Attendance from "../../project/pages/attendance";
import Leave from "../../project/pages/leave";
import Services from "../../project/pages/services";
import Sales from "../../project/pages/sales";
import VisitPurpose from "../../project/pages/visitPurpose";
import ProductRequest from "../../project/pages/ProductRequest";
import Area from "../../project/pages/area";
import Defect from "../../project/pages/defect";
import Stock from "../../project/pages/stock";
import StockLog from "../../project/pages/stockLog";
import Notification from "../../project/pages/notification";
import Activities from "../../project/pages/activities";
import Form from "../../project/pages/form";

const RenderPage = (page, key, user, privileges) => {
  switch (page) {
    case "login":
      return <Form key={key} />;
    case "menu":
      return <Menu key={key} {...privileges} />;
    case "admin":
      return <Admin key={key} {...privileges} />;
    case "user-role":
      return <UserType key={key} {...privileges} />;
    case "user-list":
      return <UserList key={key} {...privileges} />;
    case "dashboard":
      return <Dashboard key={key} {...privileges} />;
    case "location-tracking":
      return <LocationTracking key={key} {...privileges} />;
    case "page-settings":
      return <PageSettings key={key} {...privileges} />;
    case "attendance":
      return <Attendance key={key} {...privileges} />;
    case "leave":
      return <Leave key={key} {...privileges} />;
    case "shop":
      return <Shop key={key} {...privileges} />;
    case "product":
      return <Product key={key} {...privileges} />;
    case "salesman":
      return <SalesMan key={key} {...privileges} />;
    case "visit":
      return <MyVisit key={key} {...privileges} />;
    case "my-service":
      return <MyService key={key} {...privileges} />;
    case "services":
      return <Services key={key} {...privileges} />;
    case "sales":
      return <Sales key={key} {...privileges} />;
    case "visit-purpose":
      return <VisitPurpose key={key} {...privileges} />;
    case "area":
      return <Area key={key} {...privileges} />;
    case "defect":
      return <Defect key={key} {...privileges} />;
    case "transferred-products":
      return <ProductTransfer key={key} {...privileges} />;
    case "product-transfer":
      return <TransferProduct key={key} {...privileges} />;
    case "product-request":
      return <ProductRequest key={key} {...privileges} />;
    case "stock":
      return <Stock key={key} {...privileges} />;
    case "stock-log":
      return <StockLog key={key} {...privileges} />;
    case "notification":
      return <Notification key={key} {...privileges} />;
    case "activities":
      return <Activities key={key} {...privileges} />;
    default:
      return <Page404 key={key}></Page404>;
  }
};

export default RenderPage;

import React, { useEffect, useState } from "react";
import Layout from "../../../core/layout";
import ListTable from "../../../core/list/list";
import { Container } from "../../../core/layout/styels";
//src/components/styles/page/index.js
//if you want to write custom style wirte in above file
const SalesMan = (props) => {
  //to update the page title
  useEffect(() => {
    document.title = `Sales Man -Ubeco Portal`;
  }, []);

  const [attributes] = useState([
    {
      type: "text",
      placeholder: "Name",
      name: "userDisplayName",
      validation: "",
      default: "",
      label: "Name",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Sales Man Id",
      name: "salesManId",
      validation: "",
      default: "",
      label: "Sales Man Id",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Mobile",
      name: "mobile",
      validation: "",
      default: "",
      tag: true,
      label: "Mobile",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "email",
      placeholder: "Email (User Name)",
      name: "email",
      validation: "",
      default: "",
      tag: true,
      label: "Email",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "date",
      placeholder: "Date Of Birth",
      name: "dateOfBirth",
      validation: "",
      default: "",
      label: "Date Of Birth",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "select",
      placeholder: "Gender",
      name: "gender",
      collection: "",
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Gender",
      required: true,
      view: true,
      filter: true,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "Male, Female",
    },
    {
      type: "textarea",
      placeholder: "Address",
      name: "address",
      validation: "",
      default: "",
      tag: false,
      label: "Address",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "password",
      placeholder: "Password",
      name: "password",
      validation: "",
      default: "",
      tag: false,
      label: "Password",
      required: true,
      view: true,
      add: true,
      update: false,
    },
    {
      type: "image",
      placeholder: "Image",
      name: "userImage",
      validation: "",
      default: "",
      tag: true,
      label: "Image",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "user-type/select",
      placeholder: "User Type",
      name: "userType",
      validation: "",
      showItem: "role",
      default: "",
      tag: false,
      label: "User Type",
      required: false,
      view: true,
      add: false,
      update: false,
      filter: false,
    },
  ]);

  const [visits] = useState([
    {
      type: "date",
      placeholder: "Date",
      name: "date",
      validation: "",
      default: "",
      label: "Date",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
      tag: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "shop/select",
      placeholder: "Shop",
      name: "shop",
      validation: "",
      showItem: "title",
      default: "",
      tag: false,
      label: "Shop",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "user/select",
      placeholder: "Sales Man",
      name: "user",
      validation: "",
      showItem: "userDisplayName",
      default: "",
      tag: false,
      label: "Sales Man",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: false,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "visit-purpose/select",
      placeholder: "Purpose",
      name: "purpose",
      validation: "",
      showItem: "title",
      default: "",
      tag: true,
      label: "Purpose",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: false,
    },
    {
      type: "date",
      placeholder: "Check In",
      name: "checkIn",
      validation: "",
      default: "",
      tag: true,
      label: "Check In",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "date",
      placeholder: "Check Out",
      name: "checkOut",
      validation: "",
      default: "",
      tag: true,
      label: "Check Out",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Total Time",
      name: "totalTime",
      validation: "",
      default: "",
      tag: true,
      label: "Total Time",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Distance",
      name: "distance",
      validation: "",
      default: "",
      tag: false,
      label: "Distance",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "textarea",
      placeholder: "Remarks",
      name: "remarks",
      validation: "",
      default: "",
      tag: false,
      label: "Remarks",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "image",
      placeholder: "Image",
      name: "image",
      validation: "",
      default: "",
      tag: true,
      label: "Image",
      required: true,
      view: true,
      add: true,
      update: true,
    },
  ]);
  const [services] = useState([
    {
      type: "text",
      placeholder: "Invoice No",
      name: "invoiceNo",
      validation: "",
      default: "",
      label: "Invoice No",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
      tag: true,
    },
    {
      type: "date",
      placeholder: "Date",
      name: "Date",
      validation: "",
      default: "",
      label: "Date",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
      tag: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "user/select",
      placeholder: "Sales Man",
      name: "user",
      validation: "",
      showItem: "userDisplayName",
      default: "",
      tag: false,
      label: "Sales Man",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: false,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "shop/select",
      placeholder: "Shop",
      name: "shop",
      validation: "",
      showItem: "title",
      default: "",
      tag: false,
      label: "Shop",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "product/select",
      placeholder: "Product",
      name: "product",
      validation: "",
      showItem: "productName",
      default: "",
      tag: true,
      label: "Product",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: false,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "service/select",
      placeholder: "Service",
      name: "service",
      validation: "",
      showItem: "title",
      default: "",
      tag: true,
      label: "Service",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: false,
    },
    {
      type: "select",
      placeholder: "Service Type",
      name: "serviceType",
      collection: "",
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Service Type",
      required: true,
      view: true,
      filter: true,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "Spot,Intake",
    },

    {
      type: "date",
      placeholder: "Closed On",
      name: "closedOn",
      validation: "",
      default: "",
      label: "Closed On",
      required: true,
      view: true,
      add: true,
      update: true,
      tag: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "user/select",
      placeholder: "closed By",
      name: "closedBy",
      validation: "",
      showItem: "userDisplayName",
      default: "",
      tag: false,
      label: "closed By",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: false,
    },
    {
      type: "select",
      placeholder: "Status",
      name: "status",
      collection: "",
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Status",
      required: true,
      view: true,
      filter: true,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "Pending, Process, Complete",
    },
    {
      type: "textarea",
      placeholder: "Remarks",
      name: "remarks",
      validation: "",
      default: "",
      label: "Remarks",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "textarea",
      placeholder: "Close Remarks",
      name: "closeRemarks",
      validation: "",
      default: "",
      label: "Close Remarks",
      required: true,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [sales] = useState([
    {
      type: "date",
      placeholder: "Date",
      name: "date",
      validation: "",
      default: "",
      label: "Date",
      required: true,
      view: true,
      add: true,
      update: true,
      tag: true,
      filter: true,
    },
    {
      type: "text",
      placeholder: "Invoice No",
      name: "invoice",
      validation: "",
      default: "",
      label: "Invoice No",
      required: true,
      view: true,
      add: true,
      update: true,
      tag: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "user/select",
      placeholder: "Sales Man",
      name: "user",
      validation: "",
      showItem: "userDisplayName",
      default: "",
      tag: false,
      label: "Sales Man",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: false,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "shop/select",
      placeholder: "Shop",
      name: "shop",
      validation: "",
      showItem: "title",
      default: "",
      tag: false,
      label: "Shop",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "product/select",
      placeholder: "Product",
      name: "product",
      validation: "",
      showItem: "productName",
      default: "",
      tag: false,
      label: "Product",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: false,
    },
    {
      type: "number",
      placeholder: "Total Quantity",
      name: "quantity",
      validation: "",
      default: "",
      label: "Total Quantity",
      required: true,
      view: true,
      add: true,
      update: true,
      tag: true,
    },
    {
      type: "number",
      placeholder: "Total Price",
      name: "price",
      validation: "",
      default: "",
      label: "Total Price",
      required: true,
      view: true,
      add: true,
      update: true,
      tag: true,
    },

    {
      type: "select",
      placeholder: "Status",
      name: "status",
      collection: "",
      showItem: "",
      validation: "",
      default: "",
      tag: false,
      label: "Status",
      required: true,
      view: true,
      filter: true,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "Sale, Sale Return",
    },
    {
      type: "textarea",
      placeholder: "Remarks",
      name: "remarks",
      validation: "",
      default: "",
      label: "Remarks",
      required: true,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [actions] = useState([
    {
      element: "button",
      type: "subList",
      id: "myVisit",
      itemTitle: {
        name: "title",
        type: "text",
        collection: "shop",
      },
      exportPrivilege: true,
      title: "Visit",
      attributes: visits,
      params: {
        api: `myVisit`,
        parentReference: "user",
        datefilter: "true",
        itemTitle: {
          name: "title",
          type: "text",
          collection: "shop",
        },
        shortName: "Visit",
        addPrivilege: false,
        delPrivilege: false,
        updatePrivilege: false,
        customClass: "medium",
        formMode: "double",
        exportPrivilege: true,
      },
    },
    {
      element: "button",
      type: "subList",
      id: "my-service",
      itemTitle: {
        name: "title",
        type: "text",
        collection: "shop",
      },
      exportPrivilege: true,
      title: "Service",
      attributes: services,
      params: {
        api: `my-service`,
        parentReference: "user",
        datefilter: true,
        itemTitle: {
          name: "title",
          type: "text",
          collection: "shop",
        },
        shortName: "Service",
        addPrivilege: false,
        delPrivilege: false,
        updatePrivilege: false,
        customClass: "medium",
        formMode: "double",
        exportPrivilege: true,
      },
    },
    {
      element: "button",
      type: "subList",
      id: "employeeInventory",
      itemTitle: {
        name: "userDisplayName",
        type: "text",
        collection: "user",
      },
      exportPrivilege: true,
      title: "Sales",
      attributes: sales,
      params: {
        api: `employeeInventory`,
        parentReference: "user",
        datefilter: true,
        itemTitle: {
          name: "title",
          type: "text",
          collection: "shop",
        },
        shortName: "Sales",
        addPrivilege: false,
        delPrivilege: false,
        updatePrivilege: false,
        customClass: "medium",
        formMode: "double",
        exportPrivilege: true,
      },
    },
  ]);

  return (
    <Container className="noshadow">
      <ListTable
        actions={actions}
        api={`user/salesman`}
        // itemTitle={`label`}
        itemTitle={{
          name: "userDisplayName",
          type: "text",
          collection: "",
        }}
        profileImage={"userImage"}
        shortName={`Sales Man`}
        formMode={`double`}
        attributes={attributes}
        datefilter="true"
        {...props}
      ></ListTable>
    </Container>
  );
};

export default Layout(SalesMan);

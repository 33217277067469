import React, { useEffect, useState } from "react";
import Layout from "../../../core/layout";
import ListTable from "../../../core/list/list";
import { Container } from "../../../core/layout/styels";
import PopupView from "../../../core/popupview";
import { useSelector } from "react-redux";
import ViewSales from "./ViewSales";
//src/components/styles/page/index.js
//if you want to write custom style wirte in above file
const Sales = (props) => {
  //to update the page title
  useEffect(() => {
    document.title = `Sales - Ubeco Portal`;
  }, []);

  const themeColors = useSelector((state) => state.themeColors);

  const [attributes] = useState([
    {
      type: "text",
      placeholder: "Product Sale No",
      name: "invoice",
      validation: "",
      default: "",
      tag: false,
      label: "Product Sale No",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "date",
      placeholder: "Date",
      name: "date",
      validation: "",
      default: "",
      tag: true,
      label: "Date",
      required: false,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "number",
      placeholder: "Total Quantity",
      name: "quantity",
      validation: "",
      default: "",
      label: "Total Quantity",
      required: false,
      view: true,
      add: true,
      update: true,
      tag: true,
    },
    {
      type: "number",
      placeholder: "Total Price",
      name: "totalPrice",
      validation: "",
      default: "",
      tag: true,
      label: "Total Price",
      required: false,
      view: true,
      add: true,
      update: true,
    },

    {
      type: "select",
      apiType: "API",
      selectApi: "shop/select",
      placeholder: "Shop",
      name: "shop",
      validation: "",
      showItem: "title",
      default: "",
      tag: true,
      label: "Shop",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "user/selectonlysalesman",
      placeholder: "Sales Man",
      name: "user",
      validation: "",
      showItem: "userDisplayName",
      default: "",
      tag: true,
      label: "Sales Man",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "product/select",
      placeholder: "Product",
      name: "product",
      validation: "",
      showItem: "productName",
      default: "",
      tag: false,
      label: "Product",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: false,
    },
    {
      type: "select",
      placeholder: "Status",
      name: "status",
      collection: "",
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Status",
      required: true,
      view: true,
      filter: false,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "Sale, Sale Return",
    },
    {
      type: "text",
      placeholder: "Remarks",
      name: "remarks",
      validation: "",
      default: "",
      tag: false,
      label: "Remarks",
      required: false,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [actions] = useState([
    {
      element: "button",
      type: "callback",
      callback: (item, data) => {
        // Set the data for the clicked item and open the SetupMenu popup
        setOpenItemData({ item, data });
        setOpenMenuSetup(true);
      },
      itemTitle: {
        name: "user",
        type: "text",
        collection: "",
      },
      icon: "menu",
      title: "View Sales",
      params: {
        api: `scanning`,
        parentReference: "",
        itemTitle: {
          name: "user",
          type: "text",
          collection: "",
        },
        shortName: "View Sales",
        addPrivilege: true,
        delPrivilege: true,
        updatePrivilege: true,
        customClass: "medium",
      },
    },
  ]);

  // State to control the display of the SetupMenu popup
  const [openMenuSetup, setOpenMenuSetup] = useState(false);

  // State to store the data for the item that was clicked on in the ListTable
  const [openItemData, setOpenItemData] = useState(null);

  // Function to close the SetupMenu popup
  const closeModal = () => {
    setOpenMenuSetup(false);
    setOpenItemData(null);
  };

  return (
    <Container className="noshadow">
      <ListTable
        actions={actions}
        api={`employeeInventory`}
        // itemTitle={`label`}
        itemTitle={{
          name: "invoice",
          type: "text",
          collection: "",
        }}
        shortName={`Sales`}
        datefilter={"true"}
        formMode={`double`}
        attributes={attributes}
        {...props}
      ></ListTable>
      {openMenuSetup && openItemData && (
        <PopupView
          // Popup data is a JSX element which is binding to the Popup Data Area like HOC
          popupData={
            <ViewSales
              openData={openItemData}
              setMessage={props.setMessage}
            ></ViewSales>
          }
          themeColors={themeColors}
          closeModal={closeModal}
          itemTitle={{ name: "invoice", type: "text", collection: "" }}
          openData={openItemData} // Pass selected item data to the popup for setting the time and taking menu id and other required data from the list item
          customClass={"full-page"}
        ></PopupView>
      )}
    </Container>
  );
};

export default Layout(Sales);

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  ColumnContainer,
  RowContainer,
} from "../../../../styles/containers/styles.js";
import { Div, Table, TableCell, Title } from "./styles.js";
import { getData } from "../../../../../backend/api";
import { useSelector } from "react-redux";
import Layout from "../../../../core/layout";

import { NoData } from "../../../../core/list/styles.js";

import productIcon from "../../../brand/producticon.png";
import PopupView from "../../../../core/popupview/index.js";
import InvoicePDF from "./inovicePDF.js";

const OrderForm = styled.form`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 10px;
  margin-left: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin: 10px;
    gap: 0;
  }
`;

const Invoice = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  padding: 0px 20px;
  width: 300px;

  @media (max-width: 768px) {
    width: 100%;
    margin: 10px;
  }
`;

const Field = styled.label`
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  padding: 15px;
  display: flex;
  gap: 10px;
  align-items: center;
  width: 300px;

  @media (max-width: 768px) {
    width: 100%;
    margin: 10px;
  }
`;

const CustomDatePicker = styled(DatePicker)`
  border: none;
  border-radius: 4px;
  padding: 8px;
  width: 95%;
  font-size: 16px;
  color: #333;

  @media (max-width: 768px) {
    width: 90%;
    margin: 10px;
  }
`;

const DeliveryTimeSelect = styled.select`
  padding: 8px;
  border: none;
  border-radius: 4px;
  width: 60%;
  font-size: 16px;
  color: #333;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const EditOrder = styled.div`
  background-color: rgb(75, 75, 75);
  color: white;
  border: none;
  width: 150px;
  height: 60px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const RecepeImage = styled.img`
  width: 60px;
  height: 60px;
  min-width: 60px;
  margin-right: 10px;
  border-radius: 10px;
  object-fit: cover;
  && {
    .horizontal & {
      width: 40px;
      height: 40px;
    }
  }
`;

// Define a styled component for each input field
const StyledInput = styled.input`
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 5px;
  width: 40px;
  margin-right: 10px;
`;

const ViewSales = (props) => {
  const propsdata = props?.openData?.data;
  const [invoice, setInvoice] = useState([]);
  const [addedProducts, setAddedProducts] = useState([]);
  const [transferUser, setTransferUser] = useState([]);
  const [transferShop, setTransferShop] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedShop, setSelectedShop] = useState("");
  const [grandTotalQuantity, setGrandTotalQuantity] = useState(0);
  const [grandTotalSum, setGrandTotalSum] = useState(0);
  const [showPDF, setShowPDF] = useState(false);

  const themeColors = useSelector((state) => state.themeColors);

  useEffect(() => {
    getData({}, "user/selectonlysalesman").then((response) => {
      setTransferUser(response?.data);
    });
    getData({}, "shop/select").then((response) => {
      setTransferShop(response?.data);
    });

    setInvoice(propsdata?.invoice);
    setSelectedUser(propsdata?.user?._id);
    setSelectedShop(propsdata?.shop?._id);
    setGrandTotalQuantity(propsdata?.quantity);
    setGrandTotalSum(propsdata?.price);
    setAddedProducts(propsdata?.product);

    // Assuming propsdata?.date is a string in the format "2023-11-16T18:30:00.000Z"
    const parsedDate = new Date(propsdata?.date);

    // Check if parsedDate is a valid Date object
    if (!isNaN(parsedDate)) {
      setSelectedDate(parsedDate);
    } else {
      console.error("Invalid date format:", propsdata?.date);
    }
  }, [propsdata?.date, propsdata?.invoice, propsdata?.price, propsdata?.product, propsdata?.quantity, propsdata?.shop?._id, propsdata?.user?._id]);

  const closeModal = () => {
    setShowPDF(false);
    // closeModal();
  };

  const requestData = {
    invoice,
    addedProducts,
    selectedDate,
    selectedUser,
    selectedShop,
    grandTotalQuantity,
    grandTotalSum,
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <OrderForm>
        <Invoice>
          Product Sale No:
          <span>
            <h5>{invoice}</h5>
          </span>
        </Invoice>
        <Field>
          Order Date:
          <CustomDatePicker
            selected={selectedDate}
            placeholderText="Select your date"
          />
        </Field>
        <Field>
          User:
          <DeliveryTimeSelect value={selectedUser}>
            {transferUser.map((user, index) => (
              <option key={index} value={user?.id}>
                {user?.value}
              </option>
            ))}
          </DeliveryTimeSelect>
        </Field>
        <Field>
          Shop:
          <DeliveryTimeSelect value={selectedShop}>
            {transferShop.map((shop, index) => (
              <option key={index} value={shop?.id}>
                {shop?.value}
              </option>
            ))}
          </DeliveryTimeSelect>
        </Field>

        <EditOrder type="submit" onClick={() => setShowPDF(true)}>
          Print Sale
        </EditOrder>
      </OrderForm>
      <ColumnContainer className="custom">
        {showPDF && (
          <PopupView
            customClass={"print"}
            popupData={
              <InvoicePDF
                customClass={"print"}
                openData={requestData}
                inovice={invoice}
                // setMessage={props.setMessage}
                // closeModal={closeModal}
              />
            }
            themeColors={themeColors}
            closeModal={closeModal}
            itemTitle={{
              name: "userDisplayName",
              type: "text",
              collection: "user",
            }}
            openData={requestData}
          ></PopupView>
        )}
        <RowContainer>
          {addedProducts ? (
            <Table>
              <thead>
                <tr>
                  <TableCell className="left head">
                    <Div className="variants left">
                      Products You have added ({addedProducts?.length ?? 0})
                    </Div>
                  </TableCell>
                  <TableCell className="left head">
                    <Div className="variants">Quantity</Div>
                  </TableCell>
                  <TableCell className="left head">
                    <Div className="variants">Price</Div>
                  </TableCell>
                  <TableCell className="left head">
                    <Div className="variants">Total Price</Div>
                  </TableCell>
                </tr>
              </thead>
              <tbody>
                {addedProducts?.length > 0 &&
                  addedProducts.map((addedproduct, index) => (
                    <tr key={index}>
                      <TableCell className="padding left">
                        <Title>
                          {addedproduct?.products?.productName ??
                            "No Product Name"}
                        </Title>
                        <RecepeImage
                          src={
                            addedproduct?.products?.image
                              ? process.env.REACT_APP_CDN +
                                addedproduct?.products?.image
                              : productIcon
                          }
                        ></RecepeImage>
                        <br />
                        <span>
                          <b>Color:</b> {addedproduct?.products?.color}
                        </span>
                        <br />
                        <span>
                          <b>Category:</b> {addedproduct?.products?.category}
                        </span>
                      </TableCell>
                      <TableCell>
                        <StyledInput
                          placeholder="1"
                          type="number"
                          value={addedproduct?.quantity || 1}
                        />
                      </TableCell>

                      <TableCell>{addedproduct?.products?.price}</TableCell>

                      <TableCell>{addedproduct?.price}</TableCell>
                    </tr>
                  ))}
                {addedProducts.length === 0 && (
                  <TableCell colSpan={4}>
                    <NoData>No Sales added!</NoData>
                  </TableCell>
                )}
              </tbody>
            </Table>
          ) : (
            <NoData>Loading</NoData>
          )}
        </RowContainer>
      </ColumnContainer>
    </div>
  );
};

export default Layout(ViewSales);

import React, { useEffect, useState } from "react";
import Layout from "../../../core/layout";
import ListTable from "../../../core/list/list";
import { Container } from "../../../core/layout/styels";
import PopupView from "../../../core/popupview";
import { useSelector } from "react-redux";
import ViewActivities from "./ViewActivities";
//src/components/styles/page/index.js
//if you want to write custom style wirte in above file
const Activities = (props) => {
  //to update the page title
  useEffect(() => {
    document.title = `Activities - Ubeco Portal`;
  }, []);

  const themeColors = useSelector((state) => state.themeColors);

  const [attributes] = useState([
    {
      type: "select",
      apiType: "API",
      selectApi: "user/selectonlysalesman",
      placeholder: "Sales Man",
      name: "user",
      validation: "",
      showItem: "userDisplayName",
      default: "",
      tag: false,
      label: "Sales Man",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "date",
      placeholder: "Date",
      name: "date",
      validation: "",
      default: "",
      tag: true,
      label: "Date",
      required: false,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "text",
      placeholder: "Activities",
      name: "activities",
      validation: "",
      default: "",
      tag: false,
      label: "Activities",
      required: false,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
  ]);

  const [actions] = useState([
    {
      element: "button",
      type: "callback",
      callback: (item, data) => {
        // Set the data for the clicked item and open the SetupMenu popup
        setOpenItemData({ item, data });
        setOpenMenuSetup(true);
      },
      itemTitle: {
        name: "userDisplayName",
        type: "text",
        collection: "user",
      },
      icon: "menu",
      title: "View Activity",
      params: {
        api: `scanning`,
        parentReference: "",
        itemTitle: {
          name: "user",
          type: "text",
          collection: "",
        },
        shortName: "View Activity",
        addPrivilege: true,
        delPrivilege: true,
        updatePrivilege: true,
        customClass: "medium",
      },
    },
  ]);

  // State to control the display of the SetupMenu popup
  const [openMenuSetup, setOpenMenuSetup] = useState(false);

  // State to store the data for the item that was clicked on in the ListTable
  const [openItemData, setOpenItemData] = useState(null);

  // Function to close the SetupMenu popup
  const closeModal = () => {
    setOpenMenuSetup(false);
    setOpenItemData(null);
  };

  return (
    <Container className="noshadow">
      <ListTable
        actions={actions}
        api={`activities`}
        // itemTitle={`label`}
        itemTitle={{
          name: "userDisplayName",
          type: "text",
          collection: "user",
        }}
        shortName={`Activities`}
        formMode={`double`}
        attributes={attributes}
        datefilter={"true"}
        {...props}
      ></ListTable>
      {openMenuSetup && openItemData && (
        <PopupView
          // Popup data is a JSX element which is binding to the Popup Data Area like HOC
          popupData={
            <ViewActivities
              openData={openItemData}
              setMessage={props.setMessage}
            ></ViewActivities>
          }
          themeColors={themeColors}
          closeModal={closeModal}
          itemTitle={{
            name: "userDisplayName",
            type: "text",
            collection: "user",
          }}
          openData={openItemData} // Pass selected item data to the popup for setting the time and taking menu id and other required data from the list item
          customClass={"single"}
        ></PopupView>
      )}
    </Container>
  );
};

export default Layout(Activities);

import React, { useEffect } from "react";
import { FormContainer, MainContainer } from "./styles";
import { RowContainer } from "../../../styles/containers/styles";
import AutoForm from "../../../core/form";
import { postData } from "../../../../backend/api";
import withLayout from "../../../public/layout";

//src/components/styles/page/index.js
//if you want to write custom style wirte in above file
const Form = (props) => {
  //to update the page title
  useEffect(() => {
    document.title = `Form - IPH`;
  }, []);

  const formInput = [
    {
      type: "text",
      placeholder: "Name of Co ordinater",
      name: "nameOfCordinator",
      validation: "",
      default: "",
      label: "Name of Co ordinater",
      required: true,
      add: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "district/select",
      placeholder: "District",
      name: "district",
      validation: "",
      showItem: "userDisplayName",
      default: "",
      tag: false,
      label: "District",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "area/select",
      placeholder: "Area",
      updateOn: "district",
      name: "area",
      validation: "",
      showItem: "userDisplayName",
      default: "",
      tag: false,
      label: "Area",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "number",
      placeholder: "വാട്സപ്പ് നമ്പർ",
      name: "whatsappNumber",
      validation: "",
      default: "",
      label: "വാട്സപ്പ് നമ്പർ",
      required: true,
      add: true,
    },
    {
      type: "number",
      placeholder: "തഫ്ഹീം എത്ര കോപ്പി ചിലവഴിച്ചു",
      name: "thafheemCopyCount",
      validation: "",
      default: "",
      label: "തഫ്ഹീം എത്ര കോപ്പി ചിലവഴിച്ചു",
      required: true,
      add: true,
    },
    {
      type: "text",
      placeholder: "തഫ്ഹീം കുറി വഴി സാധ്യത എത്ര",
      name: "thafheemKuri",
      validation: "",
      default: "",
      label: "തഫ്ഹീം കുറി വഴി സാധ്യത എത്ര",
      required: true,
      add: true,
    },
    {
      type: "text",
      placeholder: "വിജ്ഞാന കോശം എത്ര സെറ്റ് ചെലവഴിച്ചു",
      name: "encyclopediaSpend",
      validation: "",
      default: "",
      label: "വിജ്ഞാന കോശം എത്ര സെറ്റ് ചെലവഴിച്ചു",
      required: true,
      add: true,
    },
    {
      type: "number",
      placeholder: "ഒരു വറ്ഷക്കാലയളവിൽ ഏരിയ വഴി വിറ്റഴിച്ച പുസ്തകം (സംഖ്യ)",
      name: "BooksSaleInAreaByYear",
      validation: "",
      default: "",
      label: "ഒരു വറ്ഷക്കാലയളവിൽ ഏരിയ വഴി വിറ്റഴിച്ച പുസ്തകം (സംഖ്യ)",
      required: true,
      add: true,
    },
    {
      type: "number",
      placeholder: "IPH ന് അടച്ച തുക",
      name: "iphPaidAmount",
      validation: "",
      default: "",
      label: "IPH ന് അടച്ച തുക",
      required: true,
      add: true,
    },
    {
      type: "number",
      placeholder: "അടക്കാൻ ബാക്കിയുണ്ടെങ്കിൽ എത്ര",
      name: "ifLeftHowMuch",
      validation: "",
      default: "",
      label: "അടക്കാൻ ബാക്കിയുണ്ടെങ്കിൽ എത്ര",
      required: true,
      add: true,
    },
    {
      type: "number",
      placeholder: "മുസ്ഹഫ് വഖഫ് പ്രൊജക്ട് എത്ര കോപ്പി സംഘടിക്കാൻ കഴിഞ്ഞു",
      name: "mushafVaqfCount",
      validation: "",
      default: "",
      label: "മുസ്ഹഫ് വഖഫ് പ്രൊജക്ട് എത്ര കോപ്പി സംഘടിക്കാൻ കഴിഞ്ഞു",
      required: true,
      add: true,
    },
    {
      type: "number",
      placeholder: "റമദാൻ ഗിഫ്റ്റ് എത്ര ഓർഡർ  ചെയ്തു",
      name: "ramadanGiftOrderCount",
      validation: "",
      default: "",
      label: "റമദാൻ ഗിഫ്റ്റ് എത്ര ഓർഡർ  ചെയ്തു",
      required: true,
      add: true,
    },
  ];

  const isCreatingHandler = (value, callback) => {};
  const submitChange = async (post) => {
    postData({ ...post }, "form").then((response) => {
      if (response.data.success === true) {
        props.setMessage({
          type: 1,
          content: "Submitted Successfully",
          proceed: "Okay",
        });
      }
    });
  };

  return (
    <MainContainer
      style={{
        background: "linear-gradient(to bottom right, #8a2be2, #4169e1)",
      }}
    >
      <RowContainer>
        <FormContainer>
          <AutoForm
            useCaptcha={false}
            key={"form"}
            formType={"post"}
            header={"IPH REPORT 2023-24"}
            description={
              "2023 ഏപ്രിൽ മുതൽ 2024 മാറ്ച്ച് 31 വരെയുള്ള റിപ്പോറ്ട്ട് നൽകുക"
            }
            formInput={formInput}
            submitHandler={submitChange}
            button={"Save"}
            isOpenHandler={isCreatingHandler}
            isOpen={true}
            plainForm={true}
            formMode={"double"}
          ></AutoForm>
        </FormContainer>
      </RowContainer>
    </MainContainer>
  );
};

export default withLayout(Form);

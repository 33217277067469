import React, { useEffect, useState } from "react";
import Layout from "../../../core/layout";
import ListTable from "../../../core/list/list";
import { Container } from "../../../core/layout/styels";
import PopupView from "../../../core/popupview";
import { useSelector } from "react-redux";
import ViewRequest from "./ViewRequest";
// import EditTransferProduct from "./EditTransferProduct";
//src/components/styles/page/index.js
//if you want to write custom style wirte in above file
const ProductRequest = (props) => {
  //to update the page title
  useEffect(() => {
    document.title = `Item Request - Ubeco Portal`;
  }, []);

  const themeColors = useSelector((state) => state.themeColors);
  const [attributes] = useState([
    {
      type: "text",
      placeholder: "Product Transfer No.",
      name: "invoiceNo",
      validation: "",
      default: "",
      tag: false,
      label: "Product Transfer No.",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Total Quantity",
      name: "quantity",
      validation: "",
      default: "",
      label: "Total Quantity",
      required: false,
      view: true,
      add: true,
      update: true,
      tag: true,
    },
    {
      type: "number",
      placeholder: "Total Price",
      name: "price",
      validation: "",
      default: "",
      tag: true,
      label: "Total Price",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "date",
      placeholder: "Requested Date",
      name: "requestedDate",
      validation: "",
      default: "",
      tag: true,
      label: "Requested Date",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "User",
      name: "user",
      validation: "",
      showItem: "userDisplayName",
      default: "",
      tag: true,
      label: "User",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: false,
    },
    {
      type: "date",
      placeholder: "Approved Date",
      name: "approvedDate",
      validation: "",
      default: "",
      label: "Approved Date",
      required: false,
      view: true,
      tag: true,
      add: false,
      update: false,
    },
    {
      type: "select",
      placeholder: "Status",
      name: "status",
      collection: "",
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Status",
      required: true,
      view: true,
      filter: false,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "Accepted, Pending",
    },
  ]);

  const [actions] = useState([
    {
      element: "button",
      type: "callback",
      callback: (item, data) => {
        // Set the data for the clicked item and open the SetupMenu popup
        setOpenItemData({ item, data });
        setOpenMenuSetup(true);
      },
      itemTitle: {
        name: "user",
        type: "text",
        collection: "",
      },
      icon: "menu",
      title: "View Request",
      params: {
        api: `scanning`,
        parentReference: "",
        itemTitle: {
          name: "user",
          type: "text",
          collection: "",
        },
        shortName: "View Request",
        addPrivilege: true,
        delPrivilege: true,
        updatePrivilege: true,
        customClass: "medium",
      },
    },
  ]);

  // State to control the display of the SetupMenu popup
  const [openMenuSetup, setOpenMenuSetup] = useState(false);

  // State to store the data for the item that was clicked on in the ListTable
  const [openItemData, setOpenItemData] = useState(null);

  // Function to close the SetupMenu popup
  const closeModal = () => {
    setOpenMenuSetup(false);
    setOpenItemData(null);
  };

  return (
    <Container className="noshadow">
      <ListTable
        actions={actions}
        api={`productRequest`}
        // itemTitle={`label`}
        itemTitle={{
          name: "invoiceNo",
          type: "text",
          collection: "",
        }}
        shortName={`Product Request`}
        formMode={`double`}
        attributes={attributes}
        {...props}
      ></ListTable>
      {openMenuSetup && openItemData && (
        <PopupView
        //   Popup data is a JSX element which is binding to the Popup Data Area like HOC
            popupData={
              // <SetupRecipe
              //   openData={openItemData}
              //   setMessage={props.setMessage}
              //   // Pass selected item data (Menu Title) to the popup for setting the time
              // ></SetupRecipe>
              // <div>hello</div>
              <ViewRequest
                openData={openItemData}
                setMessage={props.setMessage}
              ></ViewRequest>
            }
          themeColors={themeColors}
          closeModal={closeModal}
          itemTitle={{ name: "invoiceNo", type: "text", collection: "" }}
          openData={openItemData} // Pass selected item data to the popup for setting the time and taking menu id and other required data from the list item
          customClass={"full-page"}
        ></PopupView>
      )}
    </Container>
  );
};

export default Layout(ProductRequest);

export const projectSettings = {
  title: process.env.title ?? "Ubeco Portal",
  shortTitle: process.env.shortTitle ?? "ECP",
  description: process.env.description ?? "Ubeco Portal",
  privileges: {
    admin: "6459f25d7f6e9664fbd7486f",
    salesMan: "654ca4e93fa6628c828e96e9",
  },
  formInputView: "mixed", // classic, modern, mixed
  theme: {
    theme: "rgb(129, 2, 129)",
    themeLight: "#f7f7ff",
    pageBackground: "white",
    background: "white",
    foreground: "#4f4f4f",
    border: "#d9d9d9",
    secBackground: "white",
    secForeground: "black",
    lightBackground: "White",
    lightForeground: "Black",
    lightBorder: "Black",
    lightSecBackground: "White",
    lightSecForeground: "black",
    foregroundInvert: "white",
    backgroundInvert: "black;",
    borderThinkness: "0px",
    themeBackground: "#FFFFFF",
    themeForeground: "white",
    disabledBackground: "rgba(156, 156, 156, 0.41)",
    disabledForeground: "white",
    gradient: "-webkit-linear-gradient(302deg, rgb(129, 2, 129), rgb(90, 169, 230) 100%)",
  },
};

import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import Layout from "../../../core/layout";
import ListTable from "../../../core/list/list";
import { Container } from "../../../core/layout/styels";
import PopupView from "../../../core/popupview";
import { useSelector } from "react-redux";
import MapComponent from "./ViewRequest";
// import EditTransferProduct from "./EditTransferProduct";
//src/components/styles/page/index.js
//if you want to write custom style wirte in above file
const LocationTracking = (props) => {
  const socket = io.connect("http://localhost:8060");

  //to update the page title
  useEffect(() => {
    document.title = `Track Location - Ubeco Portal`;
  }, []);

  const themeColors = useSelector((state) => state.themeColors);

  const [attributes] = useState([
    {
      type: "text",
      placeholder: "Location",
      name: "location",
      validation: "",
      default: "",
      tag: true,
      label: "Location",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "User",
      name: "user",
      validation: "",
      default: "",
      label: "User",
      required: false,
      view: true,
      add: true,
      update: true,
      tag: true,
    },
    {
      type: "number",
      placeholder: "Latitude",
      name: "latitude",
      validation: "",
      default: "",
      label: "Latitude",
      required: false,
      view: true,
      add: true,
      update: true,
      tag: true,
    },
    {
      type: "number",
      placeholder: "Longitude",
      name: "longitude",
      validation: "",
      default: "",
      label: "Longitude",
      required: false,
      view: true,
      add: true,
      update: true,
      tag: true,
    },
  ]);

  const [actions] = useState([
    {
      element: "button",
      type: "callback",
      callback: (item, data) => {
        // Set the data for the clicked item and open the SetupMenu popup
        console.log(item, data);
        setOpenItemData({ item, data });
        setOpenMenuSetup(true);
      },
      itemTitle: {
        name: "user",
        type: "text",
        collection: "",
      },
      icon: "menu",
      title: "Track Location",
      params: {
        api: `scanning`,
        parentReference: "",
        itemTitle: {
          name: "user",
          type: "text",
          collection: "",
        },
        shortName: "Track Location",
        addPrivilege: true,
        delPrivilege: true,
        updatePrivilege: true,
        customClass: "medium",
      },
    },
  ]);

  // State to control the display of the SetupMenu popup
  const [openMenuSetup, setOpenMenuSetup] = useState(false);

  // State to store the data for the item that was clicked on in the ListTable
  const [openItemData, setOpenItemData] = useState(null);

  // Function to close the SetupMenu popup
  const closeModal = () => {
    setOpenMenuSetup(false);
    setOpenItemData(null);
  };

  socket.on("locationUpdated", (updatedLocations) => {
    window.location.reload();
    // Update your UI with the updated location list
    console.log("Received updated location list:", updatedLocations);
    // Update your UI logic here
  });

  const latitude = 37.7749;
  const longitude = -122.4194;

  return (
    <Container className="noshadow">
      <ListTable
        actions={actions}
        api={`location-tracking`}
        // itemTitle={`label`}
        itemTitle={{
          name: "location",
          type: "text",
          collection: "",
        }}
        shortName={`Track Location`}
        formMode={`double`}
        attributes={attributes}
        {...props}
      ></ListTable>
      {openMenuSetup && openItemData && (
        <PopupView
          //   Popup data is a JSX element which is binding to the Popup Data Area like HOC
          popupData={
            // <SetupRecipe
            //   openData={openItemData}
            //   setMessage={props.setMessage}
            //   // Pass selected item data (Menu Title) to the popup for setting the time
            // ></SetupRecipe>
            // <div>hello</div>
            // <ViewRequest
            //   openData={openItemData}
            //   setMessage={props.setMessage}
            // ></ViewRequest>
            <MapComponent
              openData={openItemData}
              latitude={latitude}
              longitude={longitude}
            />
          }
          themeColors={themeColors}
          closeModal={closeModal}
          itemTitle={{ name: "location", type: "text", collection: "" }}
          openData={openItemData} // Pass selected item data to the popup for setting the time and taking menu id and other required data from the list item
          customClass={"full-page"}
        ></PopupView>
      )}
    </Container>
  );
};

export default Layout(LocationTracking);

import React, { useEffect, useState } from "react";
import Layout from "../../../core/layout";
import ListTable from "../../../core/list/list";
import { Container } from "../../../core/layout/styels";
//src/components/styles/page/index.js
//if you want to write custom style wirte in above file
const Attendance = (props) => {
  //to update the page title
  useEffect(() => {
    document.title = `Attendance - Ubeco Portal`;
  }, []);

  const [attributes] = useState([
    {
      type: "select",
      apiType: "API",
      selectApi: "user/selectonlysalesman",
      placeholder: "User",
      name: "user",
      validation: "",
      showItem: "userDisplayName",
      default: "",
      tag: true,
      label: "User",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "date",
      placeholder: "Date",
      name: "date",
      validation: "",
      default: "",
      label: "Date",
      required: false,
      view: true,
      tag: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "text",
      placeholder: "Check In",
      name: "checkIn",
      validation: "",
      default: "",
      label: "Check In",
      required: false,
      view: true,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Check Out",
      name: "checkOut",
      validation: "",
      default: "",
      label: "Check Out",
      required: false,
      view: true,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Location",
      name: "location",
      validation: "",
      default: "",
      label: "Location",
      required: false,
      view: true,
      tag: false,
      add: true,
      update: true,
    },

    {
      type: "text",
      placeholder: "Working Hours",
      name: "workingHours",
      validation: "",
      default: "",
      tag: true,
      label: "Working Hours",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "Status",
      name: "status",
      collection: "",
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Status",
      required: true,
      view: true,
      filter: false,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "Punch In, Punch Out, Break",
    },
  ]);

  return (
    <Container className="noshadow">
      <ListTable
        // actions={actions}
        api={`attendance`}
        // itemTitle={`label`}
        itemTitle={{
          name: "userDisplayName",
          type: "text",
          collection: "user",
        }}
        shortName={`Attendance`}
        datefilter={"true"}
        formMode={`double`}
        viewMode="table"
        attributes={attributes}
        {...props}
      ></ListTable>
    </Container>
  );
};

export default Layout(Attendance);

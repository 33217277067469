import React from "react";
import styled from "styled-components";
const Svg = styled.svg`
  height: 1em;
`;
export const PreparingIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
    <path
      fill="currentColor"
      d="M112 0c-8.8 0-16 7.2-16 16v4c0 17.6 8.3 34.2 22.4 44.8l32 24c6 4.5 9.6 11.6 9.6 19.2v4c0 8.8 7.2 16 16 16s16-7.2 16-16v-4c0-17.6-8.3-34.2-22.4-44.8l-32-24c-6-4.5-9.6-11.6-9.6-19.2V16c0-8.8-7.2-16-16-16zM256 352H192c-88.4 0-160-71.6-160-160H416c0 88.4-71.6 160-160 160zM32 160c-17.7 0-32 14.3-32 32c0 80.5 49.5 149.4 119.7 177.9l-7.4 16.2c-5.2-1.4-10.7-2.1-16.4-2.1c-35.3 0-64 28.7-64 64s28.7 64 64 64c29.8 0 54.9-20.4 62-48H335.9l17.6 38.6c3.7 8 13.1 11.6 21.2 7.9s11.6-13.1 7.9-21.2L328.3 369.9C398.5 341.4 448 272.5 448 192c0-17.7-14.3-32-32-32H32zM140.4 401.9l10.2-22.4c13.4 2.9 27.2 4.5 41.5 4.5h64c14.2 0 28.1-1.5 41.5-4.5L321.3 432H158c-3-11.7-9.2-22-17.6-30.1zM64 448a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM224 16c0-8.8-7.2-16-16-16s-16 7.2-16 16v4c0 17.6 8.3 34.2 22.4 44.8l32 24c6 4.5 9.6 11.6 9.6 19.2v4c0 8.8 7.2 16 16 16s16-7.2 16-16v-4c0-17.6-8.3-34.2-22.4-44.8l-32-24c-6-4.5-9.6-11.6-9.6-19.2V16z"
    />
  </Svg>
);
export const DefaultIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M256 32a224 224 0 1 1 0 448 224 224 0 1 1 0-448zm0 480A256 256 0 1 0 256 0a256 256 0 1 0 0 512z"
    />
  </Svg>
);
export const SalesmanIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
    <path d="M56 56a24 24 0 1 1 48 0A24 24 0 1 1 56 56zm80 0A56 56 0 1 0 24 56a56 56 0 1 0 112 0zM0 496c0 8.8 7.2 16 16 16s16-7.2 16-16l0-130.5L1.6 341.6c-.5-.4-1.1-.9-1.6-1.3L0 496zM60 176c7 0 13.8 1.5 20 4.2V319.4L39.1 292.2c-4.5-3-7.1-8-7.1-13.3V204c0-15.5 12.5-28 28-28zm52 38.5l37.8 58.8c5.9 9.2 16 14.7 26.9 14.7H224h16H352c17.7 0 32-14.3 32-32V128c0-17.7-14.3-32-32-32H224c-17.7 0-32 14.3-32 32V256H176.7l-47.8-74.4C113.9 158.2 87.9 144 60 144c-33.1 0-60 26.9-60 60v74.9c0 16 8 31 21.4 39.9l100.2 66.8 22.7 113.5c1.7 8.7 10.2 14.3 18.8 12.6s14.3-10.2 12.6-18.8L153 379.4c-1.7-8.3-6.6-15.6-13.6-20.3L112 340.8V214.5zM224 256V128H352V256H240 224z" />
  </Svg>
);
export const ShopIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
    <path d="M0 185.8c0-6.4 1.6-12.7 4.7-18.3L82.4 25C90.8 9.6 106.9 0 124.5 0h391c17.6 0 33.7 9.6 42.1 25l77.7 142.4c3.1 5.6 4.7 11.9 4.7 18.3c0 21.1-17.1 38.2-38.2 38.2H576V496c0 8.8-7.2 16-16 16s-16-7.2-16-16V224H96V352H352V272 256h32v16V464c0 26.5-21.5 48-48 48H112c-26.5 0-48-21.5-48-48V224H38.2C17.1 224 0 206.9 0 185.8zM80 192H560h41.8c3.4 0 6.2-2.8 6.2-6.2c0-1-.3-2.1-.8-3L529.6 40.3c-2.8-5.1-8.2-8.3-14-8.3h-391c-5.9 0-11.2 3.2-14 8.3L32.8 182.8c-.5 .9-.8 1.9-.8 3c0 3.4 2.8 6.2 6.2 6.2H80zM96 464c0 8.8 7.2 16 16 16H336c8.8 0 16-7.2 16-16V384H96v80z" />
  </Svg>
);
export const MasterDataIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
    <path d="M160 384c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32H304v80c0 17.7 14.3 32 32 32h80c0 .3 0 .5 0 .8V352c0 17.7-14.3 32-32 32H160zM336 57.5L390 112H336V57.5zM160 0C124.7 0 96 28.7 96 64V352c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V144.8c0-12.7-5-24.8-13.9-33.8l-96-96.8C329.1 5.1 316.8 0 304 0H160zM32 112c0-8.8-7.2-16-16-16s-16 7.2-16 16V384c0 70.7 57.3 128 128 128H336c8.8 0 16-7.2 16-16s-7.2-16-16-16H128c-53 0-96-43-96-96V112z" />
  </Svg>
);
export const ProductIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
    <path d="M80.4 49.5L320 77.2 559.6 49.5c14.1-1.6 27.5 6.2 33.1 19.2l32 74.7c9.8 22.9-3.5 49.1-27.8 54.7L437.8 234.8c-18.9 4.4-38.6-3.1-49.9-18.9L320 120.9l-67.9 95.1c-11.3 15.8-30.9 23.2-49.9 18.9L43 198c-24.3-5.6-37.6-31.8-27.8-54.7l32-74.7c5.6-13 19-20.8 33.1-19.2zM76.7 81.3l-32 74.7c-2 4.6 .7 9.8 5.6 10.9l159.2 36.7c6.3 1.5 12.9-1 16.6-6.3l65.1-91.2L76.7 81.3zM544 236.7l32-9.1v151c0 22-15 41.2-36.4 46.6l-208 52c-7.6 1.9-15.6 1.9-23.3 0l-208-52C79 419.7 64 400.5 64 378.5v-151l32 9.1V378.5c0 7.3 5 13.7 12.1 15.5L304 443V208c0-8.8 7.2-16 16-16s16 7.2 16 16V443l195.9-49c7.1-1.8 12.1-8.2 12.1-15.5V236.7zM348.8 106.1l65.1 91.2c3.8 5.3 10.3 7.7 16.6 6.3l159.2-36.7c4.9-1.1 7.5-6.4 5.6-10.9l-32-74.7L348.8 106.1z" />
  </Svg>
);
export const ServiceIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path d="M64.7 34.6L160 107.9V160H107.9L34.6 64.7 64.7 34.6zM192 169.3c0-.4 0-.9 0-1.3V104c0-7.5-3.5-14.5-9.4-19L78.6 5C69.1-2.4 55.6-1.5 47 7L7 47c-8.5 8.5-9.4 22-2.1 31.6l80 104c4.5 5.9 11.6 9.4 19 9.4h64c.4 0 .9 0 1.3 0L271.2 293.9c-19.5 30-16.2 70.5 10.1 96.8l112 112c12.5 12.5 32.8 12.5 45.3 0l64-64c12.5-12.5 12.5-32.8 0-45.3l-112-112c-26.3-26.3-66.8-29.7-96.8-10.1L192 169.3zM304 304c17.7-17.7 46.3-17.7 64 0L480 416l-64 64L304 368c-17.7-17.7-17.7-46.3 0-64zM104 424a16 16 0 1 0 -32 0 16 16 0 1 0 32 0zM23.2 376.8C8.3 391.7 0 411.8 0 432.8C0 476.5 35.5 512 79.2 512c21 0 41.1-8.3 56-23.2L238.5 385.5c-5.7-11.2-9.3-23.1-10.9-35.3L112.1 465.7c-8.7 8.7-20.6 13.6-32.9 13.6c-25.7 0-46.6-20.8-46.6-46.6c0-12.3 4.9-24.2 13.6-32.9L189.1 257l-23.1-23.1L23.2 376.8zM479.4 160c0 44.8-23.1 84.1-58 106.8l23.5 23.5c40.7-29 67.2-76.6 67.2-130.3c0-24.8-5.7-48.3-15.7-69.3c-4.4-9.2-16.5-10.5-23.7-3.3l-67.9 67.9c-3 3-7.1 4.7-11.3 4.7H368c-8.8 0-16-7.2-16-16V118.6c0-4.2 1.7-8.3 4.7-11.3l67.9-67.9c7.2-7.2 5.9-19.3-3.3-23.7C400.3 5.7 376.8 0 352 0C296.1 0 246.9 28.6 218.3 72.1l26 20C266.8 56.3 306.7 32.6 352 32.6c10.3 0 20.2 1.2 29.7 3.5L333.6 84.2c-9.1 9.1-14.2 21.5-14.2 34.4V144c0 26.9 21.8 48.6 48.6 48.6h25.4c12.9 0 25.3-5.1 34.4-14.2l48.1-48.1c2.3 9.5 3.5 19.5 3.5 29.7z" />
  </Svg>
);
export const VisitPurpose = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
    <path d="M163.9 28.9C191.7 .7 235.8-.8 265.3 24.3c5.8 4.9 11 10.4 16.3 15.9c2.1 2.2 4.2 4.5 6.4 6.6c2.1-2.2 4.3-4.4 6.4-6.6c5.2-5.5 10.5-11 16.3-15.9C340.2-.8 384.3 .7 412.1 28.9c29.4 29.8 29.4 78.2 0 108L310.5 240.1c-6.2 6.3-14.3 9.4-22.5 9.4s-16.3-3.1-22.5-9.4L163.9 136.9c-29.4-29.8-29.4-78.2 0-108zm83.6 22.5c-16.8-17.1-44-17.1-60.8 0c-17.1 17.4-17.1 45.7 0 63.1L288 217.3 389.3 114.4c17.1-17.4 17.1-45.7 0-63.1c-16.8-17.1-44-17.1-60.8 0L299.4 80.9c-6.3 6.4-16.5 6.4-22.8 0L247.5 51.4zM151 317.4c13.1-8.8 28.6-13.4 44.4-13.4H344c30.9 0 56 25.1 56 56c0 8.6-1.9 16.7-5.4 24h5.6l94.7-56.4c8.3-4.9 17.8-7.6 27.5-7.6h1.3c28.9 0 52.3 23.4 52.3 52.3c0 17.7-9 34.2-23.8 43.8L432.6 493.9c-18.2 11.8-39.4 18.1-61 18.1H16c-8.8 0-16-7.2-16-16s7.2-16 16-16H371.5c15.5 0 30.6-4.5 43.6-12.9l119.6-77.8c5.8-3.7 9.2-10.2 9.2-17c0-11.2-9.1-20.3-20.3-20.3h-1.3c-3.9 0-7.7 1.1-11.1 3l-98.5 58.7c-2.5 1.5-5.3 2.3-8.2 2.3H344 320 256c-8.8 0-16-7.2-16-16s7.2-16 16-16h64 24c13.3 0 24-10.7 24-24s-10.7-24-24-24H195.4c-9.5 0-18.7 2.8-26.6 8.1L88.9 397.3c-2.6 1.8-5.7 2.7-8.9 2.7H16c-8.8 0-16-7.2-16-16s7.2-16 16-16H75.2L151 317.4z" />
  </Svg>
);
export const LocationIcon = () => (
  <Svg
    className="svg-inline--fa fa-location-dot"
    aria-hidden="true"
    focusable="false"
    data-prefix="fal"
    data-icon="location-dot"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 384 512"
    data-fa-i2svg=""
  >
    <path
      fill="currentColor"
      d="M272 192C272 236.2 236.2 272 192 272C147.8 272 112 236.2 112 192C112 147.8 147.8 112 192 112C236.2 112 272 147.8 272 192zM192 240C218.5 240 240 218.5 240 192C240 165.5 218.5 144 192 144C165.5 144 144 165.5 144 192C144 218.5 165.5 240 192 240zM384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192H384zM192 32C103.6 32 32 103.6 32 192C32 207.6 37.43 229 48.56 255.4C59.47 281.3 74.8 309.4 92.14 337.5C126.2 392.8 166.6 445.7 192 477.6C217.4 445.7 257.8 392.8 291.9 337.5C309.2 309.4 324.5 281.3 335.4 255.4C346.6 229 352 207.6 352 192C352 103.6 280.4 32 192 32z"
    ></path>
  </Svg>
);
export const NotificationIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
    <path
      fill="currentColor"
      d="M208 16c0-8.8 7.2-16 16-16s16 7.2 16 16V32.8c80.9 8 144 76.2 144 159.2v29.1c0 43.7 17.4 85.6 48.3 116.6l2.8 2.8c8.3 8.3 13 19.6 13 31.3c0 24.5-19.8 44.3-44.3 44.3H44.3C19.8 416 0 396.2 0 371.7c0-11.7 4.7-23 13-31.3l2.8-2.8C46.6 306.7 64 264.8 64 221.1V192c0-83 63.1-151.2 144-159.2V16zm16 48C153.3 64 96 121.3 96 192v29.1c0 52.2-20.7 102.3-57.7 139.2L35.6 363c-2.3 2.3-3.6 5.4-3.6 8.7c0 6.8 5.5 12.3 12.3 12.3H403.7c6.8 0 12.3-5.5 12.3-12.3c0-3.3-1.3-6.4-3.6-8.7l-2.8-2.8c-36.9-36.9-57.7-87-57.7-139.2V192c0-70.7-57.3-128-128-128zM193.8 458.7c4.4 12.4 16.3 21.3 30.2 21.3s25.8-8.9 30.2-21.3c2.9-8.3 12.1-12.7 20.4-9.8s12.7 12.1 9.8 20.4C275.6 494.2 251.9 512 224 512s-51.6-17.8-60.4-42.7c-2.9-8.3 1.4-17.5 9.8-20.4s17.5 1.4 20.4 9.8z"
    />
  </Svg>
);
export const AttendanceIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
    <path d="M128 16c0-8.8-7.2-16-16-16s-16 7.2-16 16V64H64C28.7 64 0 92.7 0 128v32 32V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 160 128c0-35.3-28.7-64-64-64H352V16c0-8.8-7.2-16-16-16s-16 7.2-16 16V64H128V16zM32 192H416V448c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V192zM64 96H384c17.7 0 32 14.3 32 32v32H32V128c0-17.7 14.3-32 32-32zM331.3 283.3c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L208 361.4l-52.7-52.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l64 64c6.2 6.2 16.4 6.2 22.6 0l112-112z" />
  </Svg>
);
export const LeaveIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path d="M160 16c0-8.8-7.2-16-16-16s-16 7.2-16 16V64H96c-35.3 0-64 28.7-64 64v32 32V448c0 35.3 28.7 64 64 64H416c35.3 0 64-28.7 64-64V192 160 128c0-35.3-28.7-64-64-64H384V16c0-8.8-7.2-16-16-16s-16 7.2-16 16V64H160V16zM64 192H448V448c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V192zM96 96H416c17.7 0 32 14.3 32 32v32H64V128c0-17.7 14.3-32 32-32zM331.3 283.3c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L256 313.4l-52.7-52.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L233.4 336l-52.7 52.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L256 358.6l52.7 52.7c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L278.6 336l52.7-52.7z" />
  </Svg>
);
export const ReportIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
    <path d="M32 128V448c0 17.7 14.3 32 32 32H384c17.7 0 32-14.3 32-32V128H32zm0-32H416V64c0-17.7-14.3-32-32-32H64C46.3 32 32 46.3 32 64V96zM448 64V96v16 16V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128 112 96 64C0 28.7 28.7 0 64 0H384c35.3 0 64 28.7 64 64zM112 192H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 96H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 96H240c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
  </Svg>
);
export const SalesIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
    <path d="M16 0C7.2 0 0 7.2 0 16s7.2 16 16 16H53.9c7.6 0 14.2 5.3 15.7 12.8l58.9 288c6.1 29.8 32.3 51.2 62.7 51.2H496c8.8 0 16-7.2 16-16s-7.2-16-16-16H191.2c-15.2 0-28.3-10.7-31.4-25.6L152 288H466.5c29.4 0 55-20 62.1-48.5L570.6 71.8c5-20.2-10.2-39.8-31-39.8H99.1C92.5 13 74.4 0 53.9 0H16zm90.1 64H539.5L497.6 231.8C494 246 481.2 256 466.5 256H145.4L106.1 64zM168 456a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zm80 0a56 56 0 1 0 -112 0 56 56 0 1 0 112 0zm200-24a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm0 80a56 56 0 1 0 0-112 56 56 0 1 0 0 112z" />
  </Svg>
);
export const MyServiceIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path d="M480 160c0-10.7-1.3-21.1-3.8-31l-48.9 48.9c-9 9-21.2 14.1-33.9 14.1H368c-26.5 0-48-21.5-48-48V118.6c0-12.7 5.1-24.9 14.1-33.9L383 35.8c-9.9-2.5-20.3-3.8-31-3.8c-70.7 0-128 57.3-128 128c0 9.2 1 18.1 2.8 26.7c3.2 15.2-.6 32.4-13.1 44.9L45.8 399.4C37 408.3 32 420.3 32 432.8C32 458.9 53.1 480 79.2 480c12.5 0 24.5-5 33.4-13.8L280.5 298.3c12.4-12.4 29.7-16.3 44.9-13.1c8.6 1.8 17.5 2.8 26.7 2.8c70.7 0 128-57.3 128-128zm32 0c0 88.4-71.6 160-160 160c-11.4 0-22.6-1.2-33.3-3.5c-5.6-1.2-11.5 .3-15.6 4.4L135.2 488.8c-14.9 14.9-35 23.2-56 23.2C35.5 512 0 476.5 0 432.8c0-21 8.3-41.1 23.2-56L191.1 208.9c4.1-4.1 5.6-10 4.4-15.6c-2.3-10.7-3.5-21.9-3.5-33.3C192 71.6 263.6 0 352 0c24.8 0 48.3 5.7 69.3 15.7c9.2 4.4 10.5 16.5 3.3 23.7l-67.9 67.9c-3 3-4.7 7.1-4.7 11.3V144c0 8.8 7.2 16 16 16h25.4c4.2 0 8.3-1.7 11.3-4.7l67.9-67.9c7.2-7.2 19.3-5.9 23.7 3.3c10.1 21 15.7 44.5 15.7 69.3zM88 408a16 16 0 1 1 0 32 16 16 0 1 1 0-32z" />
  </Svg>
);
export const VisitIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
    <path d="M520.6 120.9l0 0c18.7 29.6 .3 66.6-29.2 70.6c-2.6 .3-5.2 .5-7.9 .5c-16.5 0-31.2-7.2-41.5-18.5c-6.1-6.7-14.7-10.5-23.7-10.5s-17.6 3.8-23.7 10.5c-10.2 11.2-24.8 18.4-41.4 18.4c-16.5 0-31.3-7.2-41.4-18.4c-6.1-6.7-14.7-10.5-23.7-10.5s-17.7 3.8-23.7 10.5C254 184.8 239.3 192 222.7 192c-16.5 0-31.3-7.2-41.4-18.4c-6.1-6.7-14.7-10.5-23.7-10.5s-17.7 3.8-23.7 10.5C123.6 184.8 109 192 92.4 192c-2.6 0-5.2-.2-7.9-.5c-29.3-4-47.7-41-29.1-70.6l0 0L111.6 32H464.4l56.2 88.9zM483.4 224c4.1 0 8.1-.3 12.1-.8c55.5-7.4 81.8-72.5 52.1-119.4L490.3 13.1C485.2 5 476.1 0 466.4 0H109.6C99.9 0 90.8 5 85.7 13.1L28.3 103.8c-29.6 46.8-3.4 111.9 51.9 119.4c4 .5 8.1 .8 12.1 .8c19.6 0 37.5-6.4 52-17c4.8-3.5 9.2-7.6 13.2-11.9c4 4.4 8.4 8.4 13.2 11.9c14.5 10.6 32.4 17 52 17c19.6 0 37.5-6.4 52-17c4.8-3.5 9.2-7.6 13.2-12c4 4.4 8.4 8.4 13.2 11.9c14.5 10.6 32.4 17 52 17c19.8 0 37.8-6.5 52.3-17.3c4.7-3.5 9-7.4 12.9-11.7c3.9 4.3 8.3 8.3 13 11.8c14.5 10.7 32.5 17.2 52.2 17.2zM80 256c-8.8 0-16 7.2-16 16v80 16 16 64c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V384 368 352 272c0-8.8-7.2-16-16-16s-16 7.2-16 16v80H96V272c0-8.8-7.2-16-16-16zM96 384H480v64c0 17.7-14.3 32-32 32H128c-17.7 0-32-14.3-32-32V384z" />
  </Svg>
);
export const ProductTransfer = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
    <path d="M592 32H304c-8.8 0-16 7.2-16 16v80H256V48c0-26.5 21.5-48 48-48H592c26.5 0 48 21.5 48 48V464c0 26.5-21.5 48-48 48H381.3c1.8-5 2.7-10.4 2.7-16V480H592c8.8 0 16-7.2 16-16V48c0-8.8-7.2-16-16-16zM555.3 316.7c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0L496 302.6V432c0 8.8-7.2 16-16 16s-16-7.2-16-16V302.6l-36.7 36.7c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l64-64c6.2-6.2 16.4-6.2 22.6 0l64 64zM352 192H32l0 64H64 320h32V192zM0 256V192c0-17.7 14.3-32 32-32H352c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32V480c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32l0-192c-17.7 0-32-14.3-32-32zm64 32V480H320V288H64zm80 32h96c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
  </Svg>
);
export const TransferHistory = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
    <path d="M320 480H64c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32H192V144c0 26.5 21.5 48 48 48H352V448c0 17.7-14.3 32-32 32zM240 160c-8.8 0-16-7.2-16-16V32.5c2.8 .7 5.4 2.1 7.4 4.2L347.3 152.6c2.1 2.1 3.5 4.6 4.2 7.4H240zM64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V163.9c0-12.7-5.1-24.9-14.1-33.9L254.1 14.1c-9-9-21.2-14.1-33.9-14.1H64zm48 256c-8.8 0-16 7.2-16 16s7.2 16 16 16H272c8.8 0 16-7.2 16-16s-7.2-16-16-16H112zm0 64c-8.8 0-16 7.2-16 16s7.2 16 16 16H272c8.8 0 16-7.2 16-16s-7.2-16-16-16H112zm0 64c-8.8 0-16 7.2-16 16s7.2 16 16 16H272c8.8 0 16-7.2 16-16s-7.2-16-16-16H112z" />
  </Svg>
);
export const GetCustomIcon = ({ icon }) => {
  const trimmedIcon = icon && typeof icon === "string" ? icon.trim() : "";

  switch (trimmedIcon) {
    case "preparation":
      return <PreparingIcon />;
    case "salesman":
      return <SalesmanIcon />;
    case "shop":
      return <ShopIcon />;
    case "masterdata":
      return <MasterDataIcon />;
    case "product":
      return <ProductIcon />;
    case "services":
      return <ServiceIcon />;
    case "visit-purpose":
      return <VisitPurpose />;
    case "location":
      return <LocationIcon />;
    case "notification":
      return <NotificationIcon />;
    case "attendance":
      return <AttendanceIcon />;
    case "leave":
      return <LeaveIcon />;
    case "report":
      return <ReportIcon />;
    case "sales":
      return <SalesIcon />;
    case "my-service":
      return <MyServiceIcon />;
    case "visit":
      return <VisitIcon />;
    case "product-transfer":
      return <ProductTransfer />;
    case "transferred-products":
      return <TransferHistory />;
    default:
      // console.log("missing icon", icon);
      return <DefaultIcon />;
  }
};

import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

const MapComponent = ({ openData }) => {
  const latitude = openData?.data?.latitude;
  const longitude = openData?.data?.longitude;

  const position = [latitude, longitude];

  const mapContainerStyle = {
    height: "100%",
    width: "100%",
  };

  const customIcon = new L.Icon({
    iconUrl:
      "https://png.pngtree.com/png-clipart/20210824/ourmid/pngtree-location-icon-png-image_3819857.jpg",
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

  return (
    <MapContainer center={position} zoom={15} style={mapContainerStyle}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={position} icon={customIcon}>
        <Popup>
          A location at ({latitude}, {longitude})
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default MapComponent;

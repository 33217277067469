import React, { useEffect, useState } from "react";
import Layout from "../../../core/layout";
import ListTable from "../../../core/list/list";
import { Container } from "../../../core/layout/styels";
//src/components/styles/page/index.js
//if you want to write custom style wirte in above file
const StockLog = (props) => {
  //to update the page title
  useEffect(() => {
    document.title = `Stock Log - Ubeco Portal`;
  }, []);

  const [attributes] = useState([
    {
      type: "text",
      placeholder: "Item Name",
      name: "product",
      validation: "",
      showItem: "productName",
      default: "",
      tag: false,
      label: "Item Name",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: false,
    },
    {
      type: "date",
      placeholder: "date",
      name: "date",
      validation: "",
      default: "",
      label: "date",
      required: true,
      view: true,
      add: true,
      update: true,
      tag: true,
      filter: true,
    },
    {
      type: "number",
      placeholder: "Added Stock",
      name: "addedStock",
      validation: "",
      default: "",
      label: "Added Stock",
      required: true,
      view: true,
      add: true,
      update: true,
      tag: true,
    },
    {
      type: "number",
      placeholder: "Total Stock",
      name: "totalStock",
      validation: "",
      default: "",
      label: "Total Stock",
      required: true,
      view: true,
      add: true,
      update: true,
      tag: true,
    },

    {
      type: "text",
      placeholder: "Item Type",
      name: "product",
      validation: "",
      showItem: "category",
      default: "",
      tag: true,
      label: "Item Type",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: false,
    },
  ]);

  return (
    <Container className="noshadow">
      <ListTable
        // actions={actions}
        api={`stock-log`}
        // itemTitle={`label`}
        itemTitle={{
          name: "productName",
          type: "text",
          collection: "product",
        }}
        shortName={`Stock Log`}
        formMode={`double`}
        attributes={attributes}
        {...props}
      ></ListTable>
    </Container>
  );
};

export default Layout(StockLog);

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  ColumnContainer,
  RowContainer,
} from "../../../../styles/containers/styles.js";
import { Button, Div, MealItem, Table, TableCell, Title } from "./styles.js";
import { getData } from "../../../../../backend/api";
import Layout from "../../../../core/layout";

import { GetIcon } from "../../../../../icons";
// import Checkbox from "../../../../../elements/checkbox";
import { NoData, ProfileImage } from "../../../../core/list/styles.js";
import axios from "axios";

import productIcon from "../../../brand/producticon.png";

const OrderForm = styled.form`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 10px;
  margin-left: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin: 10px;
    gap: 0;
  }
`;

const Invoice = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  padding: 0px 20px;
  width: 300px;

  @media (max-width: 768px) {
    width: 100%;
    margin: 10px;
  }
`;

const Field = styled.label`
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  padding: 15px;
  display: flex;
  gap: 10px;
  align-items: center;
  width: 300px;

  @media (max-width: 768px) {
    width: 100%;
    margin: 10px;
  }
`;

const CustomDatePicker = styled(DatePicker)`
  border: none;
  border-radius: 4px;
  padding: 8px;
  width: 95%;
  font-size: 16px;
  color: #333;

  @media (max-width: 768px) {
    width: 90%;
    margin: 10px;
  }
`;

const DeliveryTimeSelect = styled.select`
  padding: 8px;
  border: none;
  border-radius: 4px;
  width: 60%;
  font-size: 16px;
  color: #333;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const PlaceOrderButton = styled.button`
  background-color: rgb(75, 75, 75);
  color: white;
  border: none;
  width: 150px;
  height: 60px;
  border-radius: 4px;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const EditOrder = styled.div`
  background-color: rgb(75, 75, 75);
  color: white;
  border: none;
  width: 150px;
  height: 60px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const RecepeImage = styled.img`
  width: 60px;
  height: 60px;
  min-width: 60px;
  margin-right: 10px;
  border-radius: 10px;
  object-fit: cover;
  && {
    .horizontal & {
      width: 40px;
      height: 40px;
    }
  }
`;

// Define a styled component for each input field
const StyledInput = styled.input`
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 5px;
  width: 40px;
  margin-right: 10px;
`;

const TransferProduct = (props) => {
  const activeUser = props.user.user;
  const propsdata = props?.openData?.data;
  const [products, setProducts] = useState([]);
  const [invoice, setInvoice] = useState([]);
  const [addedProducts, setAddedProducts] = useState([]);
  const [transferUser, setTransferUser] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedUser, setSelectedUser] = useState("");
  const [grandTotalQuantity, setGrandTotalQuantity] = useState(0);
  const [grandTotalSum, setGrandTotalSum] = useState(0);

  const [isEdited, setIsEdited] = useState(false);

  useEffect(() => {
    getData({}, "product").then((response) => {
      setProducts(response?.data?.response);
    });
    getData({}, "user/selectonlysalesman").then((response) => {
      setTransferUser(response?.data);
    });

    setInvoice(propsdata?.invoiceNo);
    setSelectedUser(propsdata?.user?._id);
    setGrandTotalQuantity(propsdata?.quantity);
    setGrandTotalSum(propsdata?.price);
    setAddedProducts(propsdata?.product);

    // Assuming propsdata?.date is a string in the format "2023-11-16T18:30:00.000Z"
    const parsedDate = new Date(propsdata?.date);

    // Check if parsedDate is a valid Date object
    if (!isNaN(parsedDate)) {
      setSelectedDate(parsedDate);
    } else {
      console.error("Invalid date format:", propsdata?.date);
    }
  }, [propsdata?.date, propsdata?.invoiceNo, propsdata?.price, propsdata?.product, propsdata?.quantity, propsdata?.user?._id]);

  useEffect(() => {
    // Calculate grand totals whenever addedProducts changes
    let totalQuantity = 0;
    let totalSum = 0;
    addedProducts.forEach((product) => {
      totalQuantity += product.quantity || 0;
      totalSum += product.price || 0;
    });
    setGrandTotalQuantity(totalQuantity);
    setGrandTotalSum(totalSum);
  }, [addedProducts]);

  const addProduct = async (item) => {
    //check if the stock is empty
    const stock = item.currentStock;
    if (stock === 0) {
      console.log("Product Stock is Empty");
    } else {
      // Check if the product is already in addedProducts
      const exists = addedProducts.some(
        (addedProduct) => addedProduct.products._id === item._id
      );

      // If the product doesn't exist in addedProducts, add it
      if (!exists) {
        // Update addedProducts state with the new product
        setAddedProducts((prevAddedProducts) => [
          ...prevAddedProducts,
          { products: item, quantity: 1, price: item?.price }, // assuming item has _id and price properties
        ]);
        // Add any additional logic you need here
      } else {
        // Handle the case where the product already exists in addedProducts
      }
    }
  };

  const deleteItem = (productId) => {
    // Filter out the product with the specified ID
    const updatedProducts = addedProducts.filter(
      (product) => product.products._id !== productId
    );

    // Update the state with the updated list of products
    setAddedProducts(updatedProducts);
  };

  const handleDateChange = (date) => {
    // Check if the date is a valid Date object or null
    if (date instanceof Date && !isNaN(date)) {
      setSelectedDate(date);
    } else {
      setSelectedDate(null);
    }
  };

  const handleQuantityChange = (productId, newQuantity) => {
    // Map through the addedProducts and update the quantity and total price
    const updatedProducts = addedProducts.map((product) => {
      if (product?.products?._id === productId) {
        const updatedQuantity = Number(newQuantity);
        const updatedTotalPrice =
          updatedQuantity === 1
            ? product.products.price
            : product.products.price * updatedQuantity;
        return {
          ...product,
          quantity: updatedQuantity,
          price: updatedTotalPrice,
        };
      } else {
        return product;
      }
    });

    // Update the state with the updated array
    setAddedProducts(updatedProducts);
  };

  const submitOrder = async () => {
    try {
      // Prepare the data to be sent in the request body
      const requestData = {
        _id: propsdata._id,
        product: addedProducts.map((product) => ({
          products: product.products._id, // Assuming _id is the product ID
          quantity: product.quantity,
          price: product.price,
        })),
        quantity: grandTotalQuantity,
        price: grandTotalSum,
        date: selectedDate,
        addedBy: activeUser,
        user: selectedUser,
        invoiceNo: invoice,
      };

      // Make the PUT request to the backend
       await axios.put(
        `${process.env.REACT_APP_API}employeeWarehouse`,
        requestData
      );

      // Handle the response if needed
    } catch (error) {
      // Handle errors
      console.error("Error submitting order:", error.message);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <OrderForm>
        <Invoice>
          Product Transfer No:
          <span>
            <h5>{invoice}</h5>
          </span>
        </Invoice>
        <Field>
          Order Date:
          <CustomDatePicker
            selected={selectedDate}
            placeholderText="Select your date"
            onChange={handleDateChange}
          />
        </Field>
        <Field>
          User:
          <DeliveryTimeSelect
            value={selectedUser}
            onChange={(e) => setSelectedUser(e.target.value)}
          >
            {transferUser.map((user, index) => (
              <option key={index} value={user?.id}>
                {user?.value}
              </option>
            ))}
          </DeliveryTimeSelect>
        </Field>
        {isEdited ? (
          <PlaceOrderButton type="submit" onClick={submitOrder}>
            Transfer Order
          </PlaceOrderButton>
        ) : (
          <EditOrder type="submit" onClick={() => setIsEdited(true)}>
            Edit Transfer
          </EditOrder>
        )}
      </OrderForm>
      <ColumnContainer className="custom">
        <RowContainer className="quarter">
          {products?.length &&
            products?.map((product) => (
              <MealItem key={product?.id} onClick={() => addProduct(product)}>
                <ProfileImage>
                  <img
                    src={
                      product?.image
                        ? process.env.REACT_APP_CDN + product?.image
                        : productIcon
                    }
                    alt="icon"
                  />
                </ProfileImage>
                <Title>
                  {product?.productName ?? "Product Name not found!"}
                  <Title>
                    <span className="price">
                      Price: {product?.price ?? "Price not found!"}
                    </span>
                    <span className="price">
                      Category: {product?.category ?? "Category not found!"}
                    </span>
                    <br />
                    <span className="price">
                      Color: {product?.color ?? "Color not found!"}
                    </span>
                    <br />
                    <span className="price">
                      Available Stock:
                      {product?.currentStock ?? "Stock not found!"}
                    </span>
                  </Title>
                </Title>
              </MealItem>
            ))}
        </RowContainer>

        <RowContainer>
          {addedProducts ? (
            <Table>
              <thead>
                <tr>
                  <TableCell className="left head">
                    <Div className="variants left">
                      Products You have added ({addedProducts?.length ?? 0})
                    </Div>
                  </TableCell>
                  <TableCell className="left head">
                    <Div className="variants">Quantity</Div>
                  </TableCell>
                  <TableCell className="left head">
                    <Div className="variants">Price</Div>
                  </TableCell>
                  {/* <TableCell className="left head">
                    <Div className="variants">Tax (5%)</Div>
                  </TableCell> */}
                  <TableCell className="left head">
                    <Div className="variants">Total Price</Div>
                  </TableCell>
                  <TableCell className="left head">
                    <Div className="variants">Remove</Div>
                  </TableCell>
                </tr>
              </thead>
              <tbody>
                {addedProducts?.length > 0 &&
                  addedProducts.map((addedproduct, index) => (
                    <tr key={index}>
                      <TableCell className="padding left">
                        <Title>
                          {addedproduct?.products?.productName ??
                            "No Product Name"}
                        </Title>
                        <RecepeImage
                          src={
                            addedproduct?.products?.image
                              ? process.env.REACT_APP_CDN +
                                addedproduct?.products?.image
                              : productIcon
                          }
                        ></RecepeImage>
                        <br />
                        <span>
                          <b>Color:</b> {addedproduct?.products?.color}
                        </span>
                        <br />
                        <span>
                          <b>Category:</b> {addedproduct?.products?.category}
                        </span>
                      </TableCell>
                      <TableCell>
                        <StyledInput
                          placeholder="1"
                          type="number"
                          value={addedproduct?.quantity || 1}
                          onChange={(e) =>
                            handleQuantityChange(
                              addedproduct?.products?._id,
                              e.target.value
                            )
                          }
                        />
                      </TableCell>

                      <TableCell>{addedproduct?.products?.price}</TableCell>

                      <TableCell>
                        {addedproduct?.products?.price * addedproduct?.quantity}
                      </TableCell>

                      {isEdited && (
                        <TableCell>
                          <Button
                            onClick={() =>
                              deleteItem(addedproduct?.products?._id)
                            }
                          >
                            <GetIcon icon={"delete"} />
                          </Button>
                        </TableCell>
                      )}
                    </tr>
                  ))}
                {addedProducts.length === 0 && (
                  <TableCell colSpan={4}>
                    <NoData>No products added!</NoData>
                  </TableCell>
                )}
              </tbody>
            </Table>
          ) : (
            <NoData>Loading</NoData>
          )}
        </RowContainer>
      </ColumnContainer>
    </div>
  );
};

export default Layout(TransferProduct);

import React, { useEffect, useState } from "react";
import Layout from "../../../core/layout";
import ListTable from "../../../core/list/list";
import { Container } from "../../../core/layout/styels";
//src/components/styles/page/index.js
//if you want to write custom style wirte in above file
const MyService = (props) => {
  //to update the page title
  useEffect(() => {
    document.title = `Service -  Urban Portal`;
  }, []);

  const [attributes] = useState([
    {
      type: "text",
      placeholder: "Invoice No",
      name: "invoiceNo",
      validation: "",
      default: "",
      label: "Invoice No",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
      tag: true,
    },
    {
      type: "date",
      placeholder: "Date",
      name: "Date",
      validation: "",
      default: "",
      label: "Date",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
      tag: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "user/selectonlysalesman",
      placeholder: "Sales Man",
      name: "user",
      validation: "",
      showItem: "userDisplayName",
      default: "",
      tag: true,
      label: "Sales Man",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "shop/select",
      placeholder: "Shop",
      name: "shop",
      validation: "",
      showItem: "title",
      default: "",
      tag: false,
      label: "Shop",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },

    {
      type: "select",
      apiType: "API",
      selectApi: "product/select",
      placeholder: "Product",
      name: "product",
      validation: "",
      showItem: "productName",
      default: "",
      tag: true,
      label: "Product",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: false,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "service/select",
      placeholder: "Service",
      name: "service",
      validation: "",
      showItem: "title",
      default: "",
      tag: true,
      label: "Service",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: false,
    },
    {
      type: "select",
      placeholder: "Service Type",
      name: "serviceType",
      collection: "",
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Service Type",
      required: true,
      view: true,
      filter: false,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "Spot,Intake",
    },

    {
      type: "textarea",
      placeholder: "Remarks",
      name: "remarks",
      validation: "",
      default: "",
      label: "Remarks",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "date",
      placeholder: "Closed On",
      name: "closedOn",
      validation: "",
      default: "",
      label: "Closed On",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "user/select",
      placeholder: "closed By",
      name: "closedBy",
      validation: "",
      showItem: "userDisplayName",
      default: "",
      tag: false,
      label: "closed By",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: false,
    },
    {
      type: "textarea",
      placeholder: "Close Remarks",
      name: "closeRemarks",
      validation: "",
      default: "",
      label: "Close Remarks",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "Status",
      name: "status",
      collection: "",
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Status",
      required: true,
      view: true,
      filter: true,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "Pending, Process, Complete",
    },
  ]);

  return (
    <Container className="noshadow">
      <ListTable
        // actions={actions}
        api={`my-service`}
        // itemTitle={`label`}
        itemTitle={{
          name: "title",
          type: "text",
          collection: "shop",
        }}
        shortName={`Service`}
        formMode={`double`}
        datefilter={"true"}
        attributes={attributes}
        {...props}
      ></ListTable>
    </Container>
  );
};

export default Layout(MyService);

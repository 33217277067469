import React, { useEffect, useState } from "react";
import Layout from "../../../core/layout";
import ListTable from "../../../core/list/list";
import { Container } from "../../../core/layout/styels";
//src/components/styles/page/index.js
//if you want to write custom style wirte in above file
const MyVisit = (props) => {
  //to update the page title
  useEffect(() => {
    document.title = `Visit - Ubeco`;
  }, []);

  const [attributes] = useState([
    {
      type: "date",
      placeholder: "Date",
      name: "date",
      validation: "",
      default: "",
      label: "Date",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "shop/select",
      placeholder: "Shop",
      name: "shop",
      validation: "",
      showItem: "title",
      default: "",
      tag: true,
      label: "Shop",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "user/selectonlysalesman",
      placeholder: "User",
      name: "user",
      validation: "",
      showItem: "userDisplayName",
      default: "",
      tag: true,
      label: "User",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "visit-purpose/select",
      placeholder: "Purpose",
      name: "purpose",
      validation: "",
      showItem: "title",
      default: "",
      tag: true,
      label: "Purpose",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: false,
    },
    {
      type: "text",
      placeholder: "Check In",
      name: "checkIn",
      validation: "",
      default: "",
      tag: true,
      label: "Check In",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Check Out",
      name: "checkOut",
      validation: "",
      default: "",
      tag: true,
      label: "Check Out",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Total Time",
      name: "totalTime",
      validation: "",
      default: "",
      tag: true,
      label: "Total Time",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Distance",
      name: "distance",
      validation: "",
      default: "",
      tag: true,
      label: "Distance",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "textarea",
      placeholder: "Remarks",
      name: "remarks",
      validation: "",
      default: "",
      tag: true,
      label: "Remarks",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "Status",
      name: "status",
      collection: "",
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Status",
      required: true,
      view: true,
      filter: false,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "Checked In, Checked Out",
    },
    {
      type: "image",
      placeholder: "Image",
      name: "image",
      validation: "",
      default: "",
      tag: true,
      label: "Image",
      required: true,
      view: true,
      add: true,
      update: true,
    },
  ]);

  return (
    <Container className="noshadow">
      <ListTable
        // actions={actions}
        api={`myVisit`}
        // itemTitle={`label`}
        itemTitle={{
          name: "title",
          type: "text",
          collection: "shop",
        }}
        shortName={`Visit`}
        datefilter={"true"}
        formMode={`double`}
        attributes={attributes}
        {...props}
      ></ListTable>
    </Container>
  );
};

export default Layout(MyVisit);

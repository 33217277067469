import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  ColumnContainer,
  RowContainer,
} from "../../../styles/containers/styles.js";
import { Button, Div, MealItem, Table, TableCell, Title } from "./styles.js";
import { getData } from "../../../../backend/api/index.js";
import Layout from "../../../core/layout/index.js";
import { GetIcon } from "../../../../icons/index.js";
import { NoData, ProfileImage } from "../../../core/list/styles.js";
import productIcon from "../../brand/producticon.png";
import axios from "axios";

const OrderForm = styled.form`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 10px;
  margin-left: 20px;
  @media (max-width: 1280px) {
    padding: 0 10px 0 0;
  }
  @media (max-width: 1024px) {
    gap: 0px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin: 10px;
    gap: 0;
  }
`;

const Invoice = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  padding: 0px 20px;
  width: 300px;
  @media (max-width: 1280px) {
    width: 240px;
    height: 50px;
  }
  @media (max-width: 1024px) {
    width: 160px;
    /* font-size: 13px; */
    gap: 5px;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin: 10px;
  }
`;

const Field = styled.label`
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  padding: 15px;
  display: flex;
  gap: 10px;
  align-items: center;
  width: 300px;
  @media (max-width: 1280px) {
    width: 240px;
    height: 20px;
  }
  @media (max-width: 1024px) {
    width: 160px;
    /* font-size: 13px; */
    gap: 5px;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin: 10px;
  }
`;

const CustomDatePicker = styled(DatePicker)`
  border: none;
  border-radius: 4px;
  padding: 8px;
  width: 95%;
  font-size: 14px;
  color: #333;
  @media (max-width: 1280px) {
    height: 10px;
  }
  @media (max-width: 768px) {
    width: 90%;
    margin: 10px;
  }
`;

const DeliveryTimeSelect = styled.select`
  padding: 8px;
  border: none;
  border-radius: 4px;
  width: 60%;
  font-size: 16px;
  color: #333;
  @media (max-width: 1280px) {
    height: 35px;
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const PlaceOrderButton = styled.button`
  background-color: rgb(75, 75, 75);
  color: white;
  border: none;
  width: 150px;
  height: 60px;
  border-radius: 4px;
  cursor: pointer;
  @media (max-width: 1280px) {
    height: 50px;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const RecepeImage = styled.img`
  width: 60px;
  height: 60px;
  min-width: 60px;
  margin-right: 10px;
  border-radius: 10px;
  object-fit: cover;
  && {
    .horizontal & {
      width: 40px;
      height: 40px;
    }
  }
`;

// Define a styled component for each input field
const StyledInput = styled.input`
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 5px;
  width: 40px;
  margin-right: 10px;
`;

const TransferProduct = (props, closeModal) => {
  const activeUser = props?.user?.user?._id;
  const [products, setProducts] = useState([]);
  const [invoice, setInvoice] = useState([]);
  const [addedProducts, setAddedProducts] = useState([]);
  const [transferUser, setTransferUser] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedUser, setSelectedUser] = useState("");

  const [grandTotalQuantity, setGrandTotalQuantity] = useState(0);
  const [grandTotalSum, setGrandTotalSum] = useState(0);

  //
  // Extract the numeric part of the invoice numbers
  const invoiceNumbers = invoice.map((item) => {
    // Assuming the invoice number format is "PTN-123"
    const numericPart = item.invoiceNo.replace("PTN-", "");
    return isNaN(numericPart) ? 0 : parseInt(numericPart);
  });

  // Find the largest number
  const lastInvoiceNo =
    invoiceNumbers.length > 0 ? Math.max(...invoiceNumbers) : null;

  useEffect(() => {
    getData({}, "product").then((response) => {
      setProducts(response?.data?.response);
    });
    getData({}, "user/selectonlysalesman").then((response) => {
      setTransferUser(response?.data);
    });
    getData({}, "employeeWarehouse").then((response) => {
      setInvoice(response?.data?.response);
    });

    // Calculate grand totals whenever addedProducts changes
    let totalQuantity = 0;
    let totalSum = 0;

    addedProducts.forEach((product) => {
      totalQuantity += product.quantity || 0;
      totalSum += product.totalPrice || 0;
    });

    setGrandTotalQuantity(totalQuantity);
    setGrandTotalSum(totalSum);
  }, [addedProducts]);

  const addProduct = async (item) => {
    //check if the stock is empty
    const stock = item.currentStock;
    if (stock === 0) {
      console.log("Product Stock is Empty");
      props.setMessage({
        type: 1,
        content: "Product Stock is Empty!",
        proceed: "Okay",
      });
    } else {
      // Check if the product is already in addedProducts
      const exists = addedProducts.some(
        (addedProduct) => addedProduct._id === item._id
      );

      // If the product doesn't exist in addedProducts, add it
      if (!exists) {
        // Update addedProducts state with the new product
        setAddedProducts((prevAddedProducts) => [
          ...prevAddedProducts,
          { ...item, quantity: 1, totalPrice: item?.price },
        ]);
        // Add any additional logic you need here
      } else {
        // Handle the case where the product already exists in addedProducts
        console.log("Product already exists in addedProducts");
        props.setMessage({
          type: 1,
          content: "Product already exists in addedProducts",
          proceed: "Okay",
        });
      }
    }
  };

  const deleteItem = (productId) => {
    // Filter out the ingredient with the specified ID
    const updatedProduct = addedProducts.filter(
      (deleteId) => deleteId._id !== productId
    );

    // Update the state with the updated list of ingredients
    setAddedProducts(updatedProduct);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleQuantityChange = (productId, newQuantity) => {
    const productToUpdate = addedProducts.find(
      (product) => product._id === productId
    );

    if (productToUpdate) {
      const updatedQuantity = Number(newQuantity);
      const availableStock = productToUpdate.currentStock || 0; // Access currentStock from product data

      // Check if the updated quantity exceeds the available stock
      if (updatedQuantity <= availableStock) {
        const updatedTotalPrice =
          updatedQuantity === 1
            ? productToUpdate.price
            : productToUpdate.price * updatedQuantity;

        const updatedProducts = addedProducts.map((product) => {
          if (product._id === productId) {
            return {
              ...product,
              quantity: updatedQuantity,
              totalPrice: updatedTotalPrice,
            };
          } else {
            return product;
          }
        });

        // Update addedProducts state with the updated quantity and total price
        setAddedProducts(updatedProducts);
      } else {
        console.log("Quantity exceeds available stock");
        props.setMessage({
          type: 1,
          content: "Quantity exceeds available stock",
          proceed: "Okay",
        });
        // Handle informing the user that the quantity exceeds available stock
      }
    }
  };

  const submitOrder = async (e) => {
    e.preventDefault(); // Prevents the default form submission behavior
    try {
      // Prepare the data to be sent in the request body
      const requestData = {
        product: addedProducts.map((product) => ({
          products: product._id, // Assuming _id is the product ID
          quantity: product.quantity,
          price: product.totalPrice,
        })),
        quantity: grandTotalQuantity,
        price: grandTotalSum,
        date: selectedDate,
        addedBy: activeUser,
        user: selectedUser,
        invoiceNo: `PTN-${lastInvoiceNo + 1}`,
      };

      // Make the POST request to the backend
      const response = await axios.post(
        `${process.env.REACT_APP_API}employeeWarehouse`,
        requestData
      );
      props.setMessage({
        type: 1,
        content: "Items Transferred Successfully",
        proceed: "Okay",
      });
      // Handle the response if needed
      console.log("Order submitted successfully:", response.data);

      // Refresh the page after a short delay to ensure setMessage updates before the refresh
      setTimeout(() => {
        window.location.reload();
      }, 2000); // Adjust the delay time if needed
    } catch (error) {
      // Handle errors
      props.setMessage({
        type: 1,
        content: "Items Transferred Failed",
        proceed: "Okay",
      });
      console.error("Error submitting order:", error.message);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100vw" }}>
      <OrderForm>
        <Invoice>
          Product Transfer No:
          <span>
            <h5>{`PTN-${lastInvoiceNo + 1}`}</h5>
          </span>
        </Invoice>
        <Field>
          Date:
          <CustomDatePicker
            selected={selectedDate}
            placeholderText="Select your date"
            // onChange={(date) => setOrderDate(date)}
            onChange={handleDateChange}
          />
        </Field>
        <Field>
          Sales Man:
          <DeliveryTimeSelect
            value={selectedUser}
            onChange={(e) => setSelectedUser(e.target.value)}
          >
            {transferUser.map((user, index) => (
              <option key={index} value={user?.id}>
                {user?.value}
              </option>
            ))}
          </DeliveryTimeSelect>
        </Field>
        <PlaceOrderButton type="submit" onClick={submitOrder}>
          Transfer Order
        </PlaceOrderButton>
      </OrderForm>
      <ColumnContainer className="custom">
        <RowContainer className="quarter">
          {products?.length &&
            products?.map((product) => (
              <MealItem key={product?.id} onClick={() => addProduct(product)}>
                <ProfileImage>
                  <img
                    src={
                      product?.image
                        ? process.env.REACT_APP_CDN + product?.image
                        : productIcon
                    }
                    alt="icon"
                  />
                </ProfileImage>
                <Title>
                  {product?.productName ?? "Product Name not found!"}
                  <Title>
                    <span className="price">
                      Price: {product?.price ?? "Price not found!"}
                    </span>
                    <span className="price">
                      Category: {product?.category ?? "Category not found!"}
                    </span>
                    <br />
                    <span className="price">
                      Color: {product?.color ?? "Color not found!"}
                    </span>
                    <br />
                    <span className="price">
                      Available Stock:
                      {product?.currentStock ?? "Stock not found!"}
                    </span>
                  </Title>
                </Title>
              </MealItem>
            ))}
        </RowContainer>

        <RowContainer>
          {addedProducts ? (
            <Table>
              <thead>
                <tr>
                  <TableCell className="left head">
                    <Div className="variants left">
                      Products You have added ({addedProducts?.length ?? 0})
                    </Div>
                  </TableCell>
                  <TableCell className="left head">
                    <Div className="variants">Quantity</Div>
                  </TableCell>
                  <TableCell className="left head">
                    <Div className="variants">Price</Div>
                  </TableCell>
                  {/* <TableCell className="left head">
                    <Div className="variants">Tax (5%)</Div>
                  </TableCell> */}
                  <TableCell className="left head">
                    <Div className="variants">Total Price</Div>
                  </TableCell>
                  <TableCell className="left head">
                    <Div className="variants">Remove</Div>
                  </TableCell>
                </tr>
              </thead>
              <tbody>
                {addedProducts?.length > 0 &&
                  addedProducts.map((addedproduct, index) => (
                    <tr key={index}>
                      <TableCell className="padding left">
                        <Title>
                          {addedproduct?.productName ?? "No Product Name"}
                        </Title>
                        <RecepeImage
                          src={
                            addedproduct?.image
                              ? process.env.REACT_APP_CDN + addedproduct?.image
                              : productIcon
                          }
                        ></RecepeImage>
                        <br />
                        <span>
                          <b>Color:</b> {addedproduct?.color}
                        </span>
                        <br />
                        <span>
                          <b>Category:</b> {addedproduct?.category}
                        </span>
                      </TableCell>
                      <TableCell>
                        <StyledInput
                          placeholder="1"
                          type="number"
                          value={addedproduct.quantity || 1}
                          onChange={(e) =>
                            handleQuantityChange(
                              addedproduct._id,
                              e.target.value
                            )
                          }
                        />
                      </TableCell>

                      <TableCell>{addedproduct?.price}</TableCell>

                      <TableCell>
                        {addedproduct?.price * addedproduct?.quantity}
                      </TableCell>

                      <TableCell>
                        <Button onClick={() => deleteItem(addedproduct?._id)}>
                          <GetIcon icon={"delete"} />
                        </Button>
                      </TableCell>
                    </tr>
                  ))}
                {addedProducts.length === 0 && (
                  <TableCell colSpan={4}>
                    <NoData>No products added!</NoData>
                  </TableCell>
                )}
              </tbody>
            </Table>
          ) : (
            <NoData>
              <GetIcon icon={"recipe"}></GetIcon>Loading
            </NoData>
          )}
        </RowContainer>
      </ColumnContainer>
    </div>
  );
};

export default Layout(TransferProduct);

import React from "react";
import { useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import { Page as PageView } from "../../../../core/form/styles";
import { getData } from "../../../../../backend/api";
import { useSelector } from "react-redux";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: "20px",
    paddingLeft: "2cm",
    paddingRight: "1.5cm",
    fontFamily: "Helvetica",
    fontSize: "12pt",
  },
  headerContainer: {
    flexDirection: "row", // lays out children (text and logo) side by side
    justifyContent: "space-between", // creates space between the text and logo
    alignItems: "center", // vertically centers the text and logo
    marginBottom: 20, // or any other value to give some space below the header
    gap: "300px",
  },
  textContainer: {
    width: "65%", // allocates 65% of the space to the text
  },
  logoContainer: {
    width: "35%", // allocates 35% of the space to the logo
  },
  logo: {
    width: 50, // or any other value
    height: 50, // or any other value, but should maintain the aspect ratio of your logo
  },
  bold: {
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
  },
  title: {
    marginBottom: 5,
    fontSize: 20,
    textAlign: "left",
    fontWeight: 600,
  },
  italic: {
    fontFamily: "Helvetica-Oblique",
  },
  address: {
    marginBottom: "20px",
    marginLeft: "auto",
    marginRight: "0",
  },

  recipetitle: {
    minHeight: "10px",
    textAlign: "left",
  },
  content: {
    marginBottom: 20,
    marginTop: 20,
    border: 1,
    padding: 10,
  },
  signature: {
    marginTop: "20px",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  signatureText: {
    borderTop: 1,
    width: 200,
    paddingTop: 5,
  },
  footerTextleft: {
    marginTop: 2,
    textAlign: "left",
  },
  footerTextright: {
    marginTop: 2,
    textAlign: "right",
  },
  singleItem: {
    display: "flex",
    flexDirection: "row",
    marginTop: 3,
  },
  singleItemTitle: {
    width: 90,
  },
  singleItemContent: {
    width: 380,
  },
  small: {
    fontSize: 10,
  },
  p: {
    marginBottom: 0,
    marginTop: 10,
  },
  table: {
    display: "table",
    width: "100%",
    borderCollapse: "collapse",
    marginTop: 20,
    tableLayout: "fixed",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCellItemName: {
    borderWidth: 1,
    borderColor: "#000",
    padding: 2,
    width: "40%",
    height: "20px", // Adjust the value to your preference
  },
  tableCell: {
    borderWidth: 1,
    borderColor: "#000",
    padding: 2,
    width: "20%",
    height: "20px", // Adjust the value to your preference
  },

  headerLineBlack: {
    border: 1,
    borderColor: "#000",
  },
  headerLineBlackSmall: {
    border: 1,
    borderColor: "#000",
    height: 1,
    marginBottom: 5,
  },
  paragraph: {
    width: "350px",
    fontSize: 14,
  },
  milkBreadTable: {
    marginTop: 20,
    border: 1,
    padding: 10,
  },
  milkBreadTitle: {
    fontSize: 20,
    fontWeight: "bold",
  },
  nutritionFacts: {
    fontSize: 14,
    fontWeight: "bold",
    marginTop: 10,
  },
  servingSize: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: 20,
    fontWeight: "600",
  },
  headerLine: {
    borderTop: 1,
    marginTop: 5,
  },
  nutrientRow: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: 20,
    fontWeight: "600",
    marginTop: 10,
  },
  headerLineSmall: {
    borderTop: 1,
    marginTop: 5,
  },
  description: {
    width: "350px",
    fontSize: 14,
  },
  sectionBottomItems: {
    marginBottom: 20,
    marginTop: 20,
  },
  sectionBottomTitle: {
    backgroundColor: "#f0f0f0", // Example background color, adjust as needed
    padding: 10, // Adjust as needed
    borderRadius: 5, // Example border radius, adjust as needed
  },
  totalPricesection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "5px",
  },
  sectionBottomHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5, // Adjust as needed
  },
  h2: {
    fontSize: 16, // Example font size, adjust as needed
    fontWeight: "bold",
    // Add other text styles (color, fontFamily, etc.) as needed
  },
  sectionBottomTimings: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

const CustomTable = ({ data, openData, employeeInventory, loginUser }) => {
  return (
    <View style={styles.table}>
      {/* Table Header */}
      <View style={styles.tableRow}>
        <View style={[styles.tableCellItemName, styles.bold]}>
          <Text>Items</Text>
        </View>
        <View style={[styles.tableCell, styles.bold]}>
          <Text>Unit Price</Text>
        </View>
        <View style={[styles.tableCell, styles.bold]}>
          <Text>Quantity</Text>
        </View>
        <View style={[styles.tableCell, styles.bold]}>
          <Text>Total Price</Text>
        </View>
      </View>
      {/* Table Data Rows */}
      {data?.length &&
        data?.map((value, index) =>
          value.product.map((item, itemIndex) => (
            <View style={styles.tableRow} key={`${index}-${itemIndex}`}>
              <View style={styles.tableCellItemName}>
                <Text>{item?.products?.productName}</Text>
              </View>
              <View style={styles.tableCell}>
                <Text>{item?.price}</Text>
              </View>
              <View style={styles.tableCell}>
                <Text>{item?.quantity}</Text>
              </View>
              <View style={styles.tableCell}>
                <Text>{item?.quantity * item?.price}</Text>
              </View>
            </View>
          ))
        )}

      {/* <Footer /> Include the Footer component here */}
      {data?.map((value, index) => (
        <View style={styles.sectionBottomItems} key={`${index}`}>
          <View style={styles.sectionBottomTitle}>
            {/* Allergens */}
            <View style={styles.totalPricesection}>
              <View>
                <View style={styles.sectionBottomHeader}>
                  <Text style={styles.h3}>
                    Customer Name: {value?.user?.userDisplayName}
                  </Text>
                </View>
                <View style={styles.sectionBottomHeader}>
                  <Text style={styles.h3}>
                    Customer Email: {value?.user?.email}
                  </Text>
                </View>
              </View>
              <View>
                <View style={styles.totalPricesection}>
                  <Text>Discount:</Text>
                  <Text>{value?.discount}%</Text>
                </View>
                <View style={styles.totalPricesection}>
                  <Text>Total Amount:</Text>
                  <Text> {value?.totalPrice}</Text>
                </View>
              </View>
            </View>

            <View style={styles.headerLineBlackSmall} />
            {/* Produced By */}
            <View style={styles.sectionBottomHeader}>
              <Text style={styles.h3}>Produced By: </Text>
              <Text style={styles.h3}>Ubeco, DUBAI</Text>
            </View>

            {/* MADE IN BAHRAIN */}
            <View style={styles.sectionBottomHeader}>
              <Text style={styles.h3}>MADE IN DUBAI</Text>
              <Text>{new Date().toLocaleString()}</Text>
            </View>

            {/* Timings */}
          </View>
        </View>
      ))}

      <View style={styles.footer}>
        <View style={styles.footerItems}>
          <View>
            <Text style={styles.footerTextleft}>
              Euros Bake W.L.L, CR.No:72669-6
            </Text>
            <Text style={styles.footerTextleft}>
              P.O BOX:80304, Sanad, Kingdom of Bahrain
            </Text>
          </View>
          <View>
            <Text style={styles.footerTextright}>
              (+973 17 627777 ) (+973 33 626044)
            </Text>
            <Text style={styles.footerTextright}>(ubeco@gmail.com)</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

// InvoicePDF component
// const InvoicePDF = ({ openData, closeModal }) => {
const InvoicePDF = (openData) => {
  const [employeeInventory, setEmployeeInventory] = useState();
  const user = useSelector((state) => state.login);
  const loginUser = user?.data?.user;
  // Fetch recipe ingredients data
  useEffect(() => {
    getData({ invoice: openData.openData.invoice }, "employeeInventory").then(
      (response) => {
        setEmployeeInventory(response?.data?.response);
      }
    );
  }, [openData]);

  // Function to generate the PDF document
  const generatePDF = () => {
    const date = openData.openData.selectedDate;
    const receivedDate = new Date(date);
    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
    };

    const formattedDate = receivedDate
      .toLocaleDateString("en-US", options)
      .replace(/,/g, ""); // Remove commas from the formatted string

    return (
      <Document author="UBECO" title={openData?.openData?.invoice}>
        <Page size="A4" style={styles.page}>
          <View style={styles.headerContainer}>
            <View style={styles.textContainer}>
              <Text style={styles.recipetitle}>
                Invoice: {openData?.inovice}
              </Text>
              <Text style={styles.recipetitle}>Date: {formattedDate}</Text>
            </View>
            <View style={styles.logoContainer}>
              <Image
                source={"../logo.jpg"} // Adjusted the way of specifying source
                style={styles.logo}
              />
            </View>
          </View>

          {/* Render the custom table component */}
          <CustomTable
            data={employeeInventory}
            openData={openData}
            loginUser={loginUser}
          />
        </Page>
      </Document>
    );
  };

  return (
    <PageView>
      <PDFViewer style={{ width: "100%", height: "600px" }}>
        {generatePDF()}
      </PDFViewer>
    </PageView>
  );
};

export default InvoicePDF;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  ColumnContainer,
  RowContainer,
} from "../../../../styles/containers/styles.js";
import { Div, Table, TableCell, Title } from "./styles.js";
import Layout from "../../../../core/layout";

import { NoData } from "../../../../core/list/styles.js";

const OrderForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 10px;
  /* margin-left: 20px; */

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin: 10px;
    gap: 0;
  }
`;

const Field = styled.label`
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 5px 0;
  padding: 15px;
  display: flex;
  gap: 10px;
  align-items: center;
  width: 300px;

  @media (max-width: 768px) {
    width: 100%;
    margin: 10px;
  }
`;

const CustomDatePicker = styled(DatePicker)`
  border: none;
  border-radius: 4px;
  padding: 8px;
  width: 95%;
  font-size: 16px;
  color: #333;

  @media (max-width: 768px) {
    width: 90%;
    margin: 10px;
  }
`;

const DeliveryTimeSelect = styled.select`
  padding: 8px;
  border: none;
  border-radius: 4px;
  width: 60%;
  font-size: 16px;
  color: #333;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ViewActivities = (props) => {
  const propsdata = props?.openData?.data;

  const [activities, setActivities] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    setActivities(propsdata?.activities);

    // Assuming propsdata?.date is a string in the format "2023-11-16T18:30:00.000Z"
    const parsedDate = new Date(propsdata?.date);

    // Check if parsedDate is a valid Date object
    if (!isNaN(parsedDate)) {
      setSelectedDate(parsedDate);
    } else {
      console.error("Invalid date format:", propsdata?.date);
    }
  }, [propsdata?.activities, propsdata?.date]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <OrderForm>
        <Field>
          Date:
          <CustomDatePicker
            selected={selectedDate}
            placeholderText="Select your date"
          />
        </Field>
        <Field>
          Sales Man:
          <DeliveryTimeSelect>
            <option value={propsdata?.user?.userDisplayName}>
              {propsdata?.user?.userDisplayName}
            </option>
          </DeliveryTimeSelect>
        </Field>
      </OrderForm>
      <ColumnContainer className="custom">
        <RowContainer>
          {activities ? (
            <Table>
              <thead>
                <tr>
                  <TableCell className="left head">
                    <Div className="variants left">
                      Activities ({activities?.length ?? 0})
                    </Div>
                  </TableCell>
                </tr>
              </thead>
              <tbody>
                {activities?.length > 0 &&
                  activities.map((activity, index) => (
                    <tr key={index}>
                      <TableCell className="padding left">
                        <Title>
                          {activity?.type ?? "No Activity Name Found"}
                        </Title>
                        <span>
                          <b>Time:</b> {activity?.time}
                        </span>
                        <br />
                        <span>
                          <b>Location:</b> {activity?.location}
                        </span>
                      </TableCell>
                    </tr>
                  ))}
                {activities.length === 0 && (
                  <TableCell colSpan={4}>
                    <NoData>No Activities added!</NoData>
                  </TableCell>
                )}
              </tbody>
            </Table>
          ) : (
            <NoData>Loading</NoData>
          )}
        </RowContainer>
      </ColumnContainer>
    </div>
  );
};

export default Layout(ViewActivities);

import React, { useEffect, useState } from "react";
import Layout from "../../../core/layout";
import ListTable from "../../../core/list/list";
import { Container } from "../../../core/layout/styels";
//src/components/styles/page/index.js
//if you want to write custom style wirte in above file
const Shop = (props) => {
  //to update the page title
  useEffect(() => {
    document.title = `Shop - Ubeco Portal`;
  }, []);

  const [attributes] = useState([
    {
      type: "text",
      placeholder: "Shop Name",
      name: "title",
      validation: "",
      default: "",
      label: "Shop Name",
      required: true,
      view: true,
      add: true,
      update: true,
    },

    {
      type: "text",
      placeholder: "Contact Person",
      name: "contactPerson",
      validation: "",
      default: "",
      tag: true,
      label: "Contact Person",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Primary Mobile",
      name: "primaryMobile",
      validation: "",
      default: "",
      tag: true,
      label: "Primary Mobile",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Secondary Mobile",
      name: "secondaryMobile",
      validation: "",
      default: "",
      tag: true,
      label: "Secondary Mobile",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "email",
      placeholder: "Email",
      name: "email",
      validation: "",
      default: "",
      tag: true,
      label: "Email",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Remarks",
      name: "remarks",
      validation: "",
      default: "",
      tag: false,
      label: "Remarks",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Added By",
      name: "addedBy",
      validation: "",
      showItem: "userDisplayName",
      default: "",
      tag: false,
      label: "Added By",
      required: false,
      view: true,
      add: false,
      update: false,
      filter: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "area/select",
      placeholder: "Area",
      name: "area",
      validation: "",
      showItem: "role",
      default: "",
      tag: false,
      label: "Area",
      required: false,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "textarea",
      placeholder: "Address",
      name: "address",
      validation: "",
      default: "",
      tag: true,
      label: "Address",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "textarea",
      placeholder: "Place",
      name: "place",
      validation: "",
      default: "",
      tag: false,
      label: "Place",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "textarea",
      placeholder: "Location",
      name: "location",
      validation: "",
      default: "",
      tag: false,
      label: "Location",
      required: false,
      view: true,
      add: false,
      update: false,
    },
    {
      type: "image",
      placeholder: "Image",
      name: "image",
      validation: "",
      default: "",
      tag: true,
      label: "Image",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Active",
      name: "active",
      validation: "",
      default: "",
      tag: false,
      label: "Active",
      required: false,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [visits] = useState([
    {
      type: "date",
      placeholder: "Date",
      name: "date",
      validation: "",
      default: "",
      label: "Date",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
      tag: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "shop/select",
      placeholder: "Shop",
      name: "shop",
      validation: "",
      showItem: "title",
      default: "",
      tag: false,
      label: "Shop",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: false,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "user/selectonlysalesman",
      placeholder: "Sales Man",
      name: "user",
      validation: "",
      showItem: "userDisplayName",
      default: "",
      tag: true,
      label: "Sales Man",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "visit-purpose/select",
      placeholder: "Purpose",
      name: "purpose",
      validation: "",
      showItem: "title",
      default: "",
      tag: true,
      label: "Purpose",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "text",
      placeholder: "Check In",
      name: "checkIn",
      validation: "",
      default: "",
      tag: true,
      label: "Check In",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Check Out",
      name: "checkOut",
      validation: "",
      default: "",
      tag: true,
      label: "Check Out",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Total Time",
      name: "totalTime",
      validation: "",
      default: "",
      tag: true,
      label: "Total Time",
      required: false,
      view: true,
      add: false,
      update: false,
    },
    {
      type: "text",
      placeholder: "Distance",
      name: "distance",
      validation: "",
      default: "",
      tag: false,
      label: "Distance",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "textarea",
      placeholder: "Remarks",
      name: "remarks",
      validation: "",
      default: "",
      tag: false,
      label: "Remarks",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "image",
      placeholder: "Image",
      name: "image",
      validation: "",
      default: "",
      tag: true,
      label: "Image",
      required: true,
      view: true,
      add: true,
      update: true,
    },
  ]);
  const [services] = useState([
    {
      type: "text",
      placeholder: "Invoice No",
      name: "invoiceNo",
      validation: "",
      default: "",
      label: "Invoice No",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
      tag: true,
    },
    {
      type: "date",
      placeholder: "Date",
      name: "Date",
      validation: "",
      default: "",
      label: "Date",
      required: true,
      view: true,
      add: true,
      update: true,
      tag: true,
      filter: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "user/select",
      placeholder: "Sales Man",
      name: "user",
      validation: "",
      showItem: "userDisplayName",
      default: "",
      tag: true,
      label: "Sales Man",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "shop/select",
      placeholder: "Shop",
      name: "shop",
      validation: "",
      showItem: "title",
      default: "",
      tag: false,
      label: "Shop",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: false,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "product/select",
      placeholder: "Product",
      name: "product",
      validation: "",
      showItem: "productName",
      default: "",
      tag: true,
      label: "Product",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: false,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "service/select",
      placeholder: "Service",
      name: "service",
      validation: "",
      showItem: "title",
      default: "",
      tag: true,
      label: "Service",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: false,
    },
    {
      type: "select",
      placeholder: "Service Type",
      name: "serviceType",
      collection: "",
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Service Type",
      required: true,
      view: true,
      filter: true,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "Spot,Intake",
    },

    {
      type: "date",
      placeholder: "Closed On",
      name: "closedOn",
      validation: "",
      default: "",
      label: "Closed On",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "user/select",
      placeholder: "Closed By",
      name: "closedBy",
      validation: "",
      showItem: "userDisplayName",
      default: "",
      tag: true,
      label: "Closed By",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: false,
    },
    {
      type: "select",
      placeholder: "Status",
      name: "status",
      collection: "",
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Status",
      required: true,
      view: true,
      filter: true,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "Pending, Process, Complete",
    },
    {
      type: "textarea",
      placeholder: "Remarks",
      name: "remarks",
      validation: "",
      default: "",
      label: "Remarks",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "textarea",
      placeholder: "Close Remarks",
      name: "closeRemarks",
      validation: "",
      default: "",
      label: "Close Remarks",
      required: true,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [sales] = useState([
    {
      type: "date",
      placeholder: "Date",
      name: "date",
      validation: "",
      default: "",
      label: "Date",
      required: true,
      view: true,
      add: true,
      update: true,
      tag: true,
      filter: true,
    },
    {
      type: "text",
      placeholder: "Invoice No",
      name: "invoice",
      validation: "",
      default: "",
      label: "Invoice No",
      required: true,
      view: true,
      add: true,
      update: true,
      tag: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "user/select",
      placeholder: "Sales Man",
      name: "user",
      validation: "",
      showItem: "userDisplayName",
      default: "",
      tag: true,
      label: "Sales Man",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "shop/select",
      placeholder: "Shop",
      name: "shop",
      validation: "",
      showItem: "title",
      default: "",
      tag: false,
      label: "Shop",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: false,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "product/select",
      placeholder: "Product",
      name: "product",
      validation: "",
      showItem: "productName",
      default: "",
      tag: false,
      label: "Product",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: false,
    },
    {
      type: "number",
      placeholder: "Total Quantity",
      name: "quantity",
      validation: "",
      default: "",
      label: "Total Quantity",
      required: true,
      view: true,
      add: true,
      update: true,
      tag: true,
    },
    {
      type: "number",
      placeholder: "Total Price",
      name: "price",
      validation: "",
      default: "",
      label: "Total Price",
      required: true,
      view: true,
      add: true,
      update: true,
      tag: true,
    },

    {
      type: "select",
      placeholder: "Status",
      name: "status",
      collection: "",
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Status",
      required: true,
      view: true,
      filter: true,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "Sale, Sale Return",
    },
    {
      type: "textarea",
      placeholder: "Remarks",
      name: "remarks",
      validation: "",
      default: "",
      label: "Remarks",
      required: true,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [actions] = useState([
    {
      element: "button",
      type: "subList",
      id: "myVisit",
      itemTitle: {
        name: "userDisplayName",
        type: "text",
        collection: "user",
      },
      exportPrivilege: true,
      title: "Visit",
      attributes: visits,

      params: {
        api: `myVisit`,
        parentReference: "shop",
        datefilter: "true",
        itemTitle: {
          name: "userDisplayName",
          type: "text",
          collection: "user",
        },
        shortName: "Visit",
        addPrivilege: false,
        delPrivilege: false,

        updatePrivilege: false,
        customClass: "medium",
        formMode: "single",
        exportPrivilege: true,
      },
    },
    {
      element: "button",
      type: "subList",
      id: "my-service",
      itemTitle: {
        name: "userDisplayName",
        type: "text",
        collection: "user",
      },
      exportPrivilege: true,
      title: "Service",
      attributes: services,
      params: {
        api: `my-service`,
        parentReference: "shop",
        datefilter: "true",
        itemTitle: {
          name: "userDisplayName",
          type: "text",
          collection: "user",
        },
        shortName: "Service",
        addPrivilege: false,
        delPrivilege: false,
        updatePrivilege: false,
        customClass: "medium",
        formMode: "double",
        exportPrivilege: true,
      },
    },
    {
      element: "button",
      type: "subList",
      id: "employeeInventory",
      itemTitle: {
        name: "userDisplayName",
        type: "text",
        collection: "user",
      },
      exportPrivilege: true,
      title: "Sales",
      attributes: sales,
      params: {
        api: `employeeInventory`,
        parentReference: "shop",
        datefilter: "true",
        itemTitle: {
          name: "userDisplayName",
          type: "text",
          collection: "user",
        },
        shortName: "Sales",
        addPrivilege: false,
        delPrivilege: false,
        updatePrivilege: false,
        customClass: "medium",
        formMode: "double",
        exportPrivilege: true,
      },
    },
  ]);

  return (
    <Container className="noshadow">
      <ListTable
        actions={actions}
        api={`shop`}
        // itemTitle={`label`}
        itemTitle={{
          name: "title",
          type: "text",
          collection: "",
        }}
        shortName={`Shop`}
        profileImage={"image"}
        formMode={`double`}
        attributes={attributes}
        {...props}
      ></ListTable>
    </Container>
  );
};

export default Layout(Shop);

import React, { useEffect, useState } from "react";
import Layout from "../../../core/layout";
import ListTable from "../../../core/list/list";
import { Container } from "../../../core/layout/styels";
//src/components/styles/page/index.js
//if you want to write custom style wirte in above file
const Product = (props) => {
  //to update the page title
  useEffect(() => {
    document.title = `Product & Accessories - Ubeco Portal`;
  }, []);

  const [attributes] = useState([
    {
      type: "text",
      placeholder: " Id",
      name: "productId",
      validation: "",
      default: "",
      label: " Id",
      required: true,
      view: true,
      add: true,
      update: true,
      tag: true,
    },
    {
      type: "text",
      placeholder: " Name",
      name: "productName",
      validation: "",
      default: "",
      label: " Name",
      required: true,
      view: true,
      add: true,
      update: true,
      tag: false,
    },
    {
      type: "textarea",
      placeholder: "Description",
      name: "description",
      validation: "",
      default: "",
      tag: false,
      label: "Description",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "General Price",
      name: "generalPrice",
      validation: "",
      default: "",
      tag: true,
      label: "General Price",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Premium Customer Price",
      name: "premiumCustomerPrice",
      validation: "",
      default: "",
      tag: true,
      label: "Premium Customer Price",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "image",
      placeholder: "Image",
      name: "image",
      validation: "",
      default: "",
      tag: true,
      label: "Image",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "Category",
      name: "category",
      collection: "",
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Category",
      required: true,
      view: true,
      filter: true,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "Product,Accessories",
    },
  ]);

  return (
    <Container className="noshadow">
      <ListTable
        // actions={actions}
        api={`product`}
        // itemTitle={`label`}
        itemTitle={{
          name: "productName",
          type: "text",
          collection: "",
        }}
        profileImage={"image"}
        shortName={`Product & Accessories`}
        formMode={`double`}
        attributes={attributes}
        {...props}
      ></ListTable>
    </Container>
  );
};

export default Layout(Product);
